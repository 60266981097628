// Mui Components
import { Box, Link } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";

// Icons
import GalleryIconActive from "../../../assets/icons/nav/icon@gallery-a.svg";

// Styles
import { styles } from "../styles";

function PostRequests() {
  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={GalleryIconActive} label="Gallery" />
        </Box>
        <Box sx={styles.buttons}>
          <MainButton size="large" variant="contained">
            Add New Content
          </MainButton>
        </Box>
      </Box>

      <ContentCard label="Available Contents" transparent count="126">
        <Box sx={styles.tabs}>
          <Link href="#">
            Added By Members
          </Link>
          <Link href="#">Added By Influencers</Link>
          <Link href="#" sx={styles.tabsActive}>Waiting Post Requests</Link>
        </Box>
        
        
      </ContentCard>
    </DashboardLayout>
  );
}

export default PostRequests;
