import React, { useEffect } from "react";
import { request } from "./services/axios";

// Router
import RouterContainer from "./router";

// Store
import { authStore } from "./store/auth";

function App() {
  const userTokens = authStore((state) => state.userTokens);
  if (userTokens && userTokens.accessToken) {
    request.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${userTokens.accessToken}`;
  }
  return <RouterContainer />;
}

export default App;
