import create from 'zustand'
import { persist } from 'zustand/middleware'

export const authStore = create(
  persist(
    (set, get) => ({
      isAuth: false,
      userTokens: null,
      updateAuthStatus: (status) => set((state) => ({ isAuth: status })),
      setUserTokens: (data) => set((state) => ({ userTokens: data }))
    }),
    {
      name: 'auth', // unique name
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }
  )
)