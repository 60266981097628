import React, { useState, useEffect } from "react";

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import Datatable from "../../../components/@core/datatable";

// Icons
import DashboardIconActive from "../../../assets/icons/nav/icon@dashboard-a.svg";

import {
  textRenderer,
  actionsRenderer,
} from "../../../components/@core/datatable/renderers";

// Styles
import { styles } from "./styles";

function MailTemplates() {
  const tableHeads = [
    { name: "Type", slug: "type" },
    { name: "Name", slug: "name" },
    { name: "Subject", slug: "subject" },
    { name: "Content", slug: "content" },
    { name: "Sender", slug: "sender" },
    { name: "Language Code", slug: "languageCode" },
    { name: "#", slug: "action" },
  ];

  const tableItems = [
    {
      type: textRenderer("Marketing"),
      name: textRenderer("Marketing 2020 Mailing"),
      subject: textRenderer("Hello"),
      content: textRenderer("Mail Content"),
      sender: textRenderer("mail@mail.com"),
      languageCode: textRenderer("EN"),
      action: actionsRenderer(),
    },
    {
      type: textRenderer("Marketing"),
      name: textRenderer("Marketing 2020 Mailing"),
      subject: textRenderer("Hello"),
      content: textRenderer("Mail Content"),
      sender: textRenderer("mail@mail.com"),
      languageCode: textRenderer("EN"),
      action: actionsRenderer(),
    },
    {
      type: textRenderer("Marketing"),
      name: textRenderer("Marketing 2020 Mailing"),
      subject: textRenderer("Hello"),
      content: textRenderer("Mail Content"),
      sender: textRenderer("mail@mail.com"),
      languageCode: textRenderer("EN"),
      action: actionsRenderer(),
    },
    {
      type: textRenderer("Marketing"),
      name: textRenderer("Marketing 2020 Mailing"),
      subject: textRenderer("Hello"),
      content: textRenderer("Mail Content"),
      sender: textRenderer("mail@mail.com"),
      languageCode: textRenderer("EN"),
      action: actionsRenderer(),
    }            
  ];

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={DashboardIconActive} label="Mail Templates" />
        </Box>
        <Box>
          <MainButton size="large" variant="contained">
            Create Mail Template
          </MainButton>
        </Box>
      </Box>
      <Datatable
        headers={tableHeads}
        items={tableItems}
        tableLabel={"Mail Template Items"}
        tableCount={"64"}
      />
    </DashboardLayout>
  );
}

export default MailTemplates;
