export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  profileInputs: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrap: {
    flex: "0 0 49%",
    maxWidth: "49%",
    "&:nth-of-type(3) .MuiFormControl-root": {
      marginBottom: 0,
    },
    "&:nth-of-type(4) .MuiFormControl-root": {
      marginBottom: 0,
    },
    "& .MuiFormControl-root .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
    },
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: "50px",
    "& > *": {
      marginRight: "30px!important",
    },
  },
  saveButton: {
    width: "auto!important",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  secondary: {
    width: "auto!important",
    paddingLeft: "25px",
    paddingRight: "25px",
    color: "#E02B26",
    backgroundColor: "#FFE4E3",
    "&:hover": {
      color: "#E02B26",
      backgroundColor: "#FFE4E3",
    },
  },
};
