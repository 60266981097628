// Mui Components
import { Button } from "@mui/material";
// Styles
import { styles } from "./styles";

const MainButton = (props) => {
  return (
    <Button {...props} sx={[styles.button, props.sx]}>
      {props.children}
    </Button>
  );
};

export default MainButton;
