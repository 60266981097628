import React from "react";

// Mui Components
import { Box, Typography, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputAdornment from "@mui/material/InputAdornment";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import DropdownButtonIcon from "../../../components/@core/dropdown-button-icon";
import Input from "../../../components/@core/input";

// Icons
import InfluencersIconActive from "../../../assets/icons/nav/icon@influencers-a.svg";
import IconBell from "../../../assets/icons/icon@bell.svg";
import IconMessage from "../../../assets/icons/icon@message.svg";
import IconTrashBlack from "../../../assets/icons/icon@trash-black.svg";
import IconWaitingResponse from "../../../assets/icons/icon@waiting.svg";
import IconContract from "../../../assets/icons/icon@contract.svg";
import IconReferral from "../../../assets/icons/icon@referrals.svg";
import IconLocation from "../../../assets/icons/icon@location-small.svg";
import IconReferralInput from "../../../assets/icons/icon@referral-small.svg";

// Styles
import { styles, StyledMenu } from "./styles";

function AgentDetail() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={InfluencersIconActive} label="Agent Profile" />
        </Box>
      </Box>
      <ContentCard label="Profile Details">
        <Box sx={styles.influencerSummary}>
          <Box>
            <Box sx={styles.nameContainer}>
              <Box sx={styles.nameAvatar}>
                <img
                  src={"https://i.pravatar.cc/51"}
                  alt=""
                  style={styles.avatarImg}
                />
                <Box sx={styles.nameCount}>2</Box>
              </Box>
              <Box>
                <Typography sx={styles.influencerName}>Name Surname</Typography>
                <Typography sx={styles.influencerSubHero}>AGENT</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginLeft: "20px", marginRight: "20px" }}>
            <DropdownButtonIcon
              id="registered-button"
              aria-controls={open ? "registered-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              prevIcon={IconWaitingResponse}
              sx={styles.dbutton}
              size="large"
            >
              Awaiting Response
            </DropdownButtonIcon>
            <StyledMenu
              id="registered-menu"
              MenuListProps={{
                "aria-labelledby": "registered-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <EditIcon />
                Edit
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <FileCopyIcon />
                Duplicate
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleClose} disableRipple>
                <ArchiveIcon />
                Archive
              </MenuItem>
            </StyledMenu>
          </Box>
          <Box sx={styles.buttons}>
            <IconButton aria-label="messages" sx={styles.button}>
              <img src={IconBell} alt="" />
            </IconButton>
            <IconButton aria-label="messages" sx={styles.button}>
              <img src={IconMessage} alt="" />
            </IconButton>
            <IconButton aria-label="messages" sx={styles.button}>
              <img src={IconTrashBlack} alt="" />
            </IconButton>
          </Box>
          <Box sx={styles.influencerValues}>
            <Box>
              <Box sx={styles.valueLabel}>TOTAL REFERRED VALUE</Box>
              <Box sx={styles.valueDetail}>34.600 $</Box>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.influencerSummaryStats}>
          <Box sx={styles.influencerSummaryStat}>
            <img src={IconContract} alt="" />
            <Box>
              <Box sx={styles.statValue}>2</Box>
              <Box sx={styles.statDesc}>ACTIVE CONTRATS</Box>
            </Box>
          </Box>
          <Box sx={styles.influencerSummaryStat}>
            <img src={IconReferral} alt="" />
            <Box>
              <Box sx={styles.statValue}>21</Box>
              <Box sx={styles.statDesc}>REFERRALS</Box>
            </Box>
          </Box>
        </Box>
      </ContentCard>
      <ContentCard label="Profile Details">
        <Box sx={styles.profileInputs}>
          <Box sx={styles.fieldWrap}>
            <Input disabled={true} label="Name Surname" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input disabled label="name.srnm@wpro.com" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input disabled label="000 000 000 00" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input
              disabled
              select
              label="Referral"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconReferralInput} alt="" />
                  </InputAdornment>
                ),
              }}
              defaultValue="a"
            >
              <MenuItem value={"a"}>A</MenuItem>
              <MenuItem value={"b"}>B</MenuItem>
            </Input>
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input disabled select label="Language" defaultValue="english">
              <MenuItem value={"turkish"}>Turkish</MenuItem>
              <MenuItem value={"english"}>English</MenuItem>
            </Input>
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input
              disabled
              label="Address Informations"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={IconLocation} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </ContentCard>
    </DashboardLayout>
  );
}

export default AgentDetail;
