// Mui Components
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";

export const styles = {
  tableHead: {
    color: "#A7A7A7",
    fontSize: "10px",
    border: "unset",
    lineHeight: "14px",
    padding: "12px",
    textTransform: "uppercase",
  },
  tableCell: {
    border: "unset",
    cursor: "pointer",
  },
  tableContainer: {
    marginTop: "30px",
    padding: "20px 10px",
    borderRadius: "25px",
    backgroundColor: "#F6F6F6",
    border: "solid 1px #D9D9D9",
  },
  tableText: {
    color: "#1F1F1F",
    fontSize: "14px",
    fontWeight: 600,
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  actionButton: {
    width: "unset!important",
    marginLeft: "10px",
    maxWidth: "unset",
  },
  nameContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  nameAvatar: {
    position: "relative",
  },
  avatarBadge: {
    position: "absolute",
    right: "-5px",
    top: "2px",
  },
  avatarImg: {
    width: "40px",
    height: "40px",
    objectFit: "cover",
    borderRadius: "100%",
  },
  influencerName: {
    color: "#1F1F1F",
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "20px",
  },
  statusItems: {
    display: "flex",
    flexWrap: "wrap",
  },
  statusItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginRight: "30px",
    "& img": {
      width: "13px",
      height: "13px",
      objectFit: "contain",
      marginLeft: "6px",
    },
  },
  statusText: {
    color: "#1F1F1F",
    fontSize: "14px",
    fontWeight: 600,
  },
  dateText: {
    color: "#1F1F1F",
    fontSize: "14px",
    fontWeight: 600,
  },
  dateHours: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginTop: "3px",
  },
  dbutton: {
    color: "#000",
    justifyContent: "flex-start",
    border: "solid 1px #E4E4E4",
    backgroundColor: "#fff!important",
    "& .MuiButton-endIcon": {
      marginLeft: "auto",
    },
  },
  dropdownIcon: {
    width: "25px",
    height: "22px",
    marginRight: "15px",
    objectFit: "contain",
  },
  tableActions: {
    marginTop: "50px",
    padding: "0 17px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableTypes: {
    display: "flex",
    flexWrap: "wrap",
  },
  tableType: {
    color: "#000",
    fontSize: 14,
    marginRight: "25px",
    "& img": {
      height: "12px",
      marginRight: "10px",
    },
  },
  pagination: {
    "& .MuiButtonBase-root": {
      backgroundColor: "#fff",
    },
    "& .Mui-selected:hover": {
      color: "#fff!important",
      borderColor: "#E02B26!important",
      backgroundColor: "#E02B26!important",
    },
    "& .MuiButtonBase-root[aria-current='true']": {
      color: "#fff",
      borderColor: "#E02B26",
      backgroundColor: "#E02B26",
    },
  },
  tableHeader: {
    marginTop: "50px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  labelArea: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingLeft: "30px",
  },
  label: {
    color: "#000",
    fontSize: "16px",
    fontWeight: 600,
  },
  count: {
    height: "27px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "10px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "27px",
    backgroundColor: "#E02B26",
    borderRadius: "16px",
  },
  filterMini: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& .MuiFormControl-root": {
      width: "370px!important",
      marginBottom: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "unset",
    },
    "& .MuiOutlinedInput-input": {
      height: "40px",
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: "6px",
      overflow: "hidden",
      backgroundColor: "#F6F6F6",
    },
  },
  filterToggle: {
    width: "42px!important",
    height: "40px!important",
    minWidth: "unset!important",
    marginLeft: "10px",
    borderRadius: "6px",
  },
  logoContainer: {
    width: "40px",
    height: "40px",
    "& img": {
      width: "40px",
      height: "40px",
      objectFit: "cover",
      borderRadius: "100%",
    },
  },
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "25px",
    marginBottom: "25px",
    padding: "30px",
    border: "solid 1px #D9D9D9",
    borderRadius: "24px",
  },
  filterItem: {
    flex: "0 0 14%",
    maxWidth: "14%",

    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      marginTop: "1px",
    },

    "& .MuiFormControl-root": {
      width: "100%",
    },

    "& .MuiButton-root": {
      height: "40px",
    },
  },
  activeCategory: {
    backgroundColor: "#f1f1f1",
  },
  filterItemText: {
    flex: "0 0 25%",
    maxWidth: "25%",
  },
  filterCategories: {
    flex: "0 0 100%",
    maxWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingTop: "15px",

    "& .MuiButtonBase-root": {
      flex: "0 0 13.8%",
      maxWidth: "13.8%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      padding: "10px 15px",
      border: "solid 1px #D9D9D9",
      justifyContent: "flex-start",
      borderRadius: "24px",
      fontSize: "13px",
      fontWeight: 600,

      "& img": {
        height: "20px",
        marginRight: "10px",
      },
    },
  },
  activeFilters: {
    marginTop: "15px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    flex: "0 0 100%",
    maxWidth: "100%",
  },
  filtersLabel: {
    fontSize: "14px",
    fontWeight: 600,
    flex: "0 0 100%",
    maxWidth: "100%",
    marginBottom: "10px",
  },
  activeFilterItem: {
    flex: "0 0 300px",
    maxWidth: "300px",
    color: "#fff",
    fontSize: "13px",
    fontWeight: 600,
    padding: "10px",
    borderRadius: "10px",
    background: "#E02B26",
    marginRight: "10px",
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",

    "& svg": {
      height: "15px",
    },
  },
  cellDropdown: {
    "& .MuiFormControl-root": {
      backgroundColor: "#fff",
    },
  },
};

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
