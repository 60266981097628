export const styles = {
  items: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  item: {
    flex: "0 0 49%",
    maxWidth: "49%",
    padding: "32px",
    marginBottom: '20px',
    backgroundColor: "#333",
    borderRadius: "24px",
  },
  label: {
    color: "#fff",
    fontSize: "22px",
    fontWeight: 600,
    marginBottom: "6px",
  },
  desc: {
    color: "#FFE4E3",
    fontSize: "10px",
  },
  bottom: {
    marginTop: "15px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  avatarsWrap: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatars: {},
  avatarsDesc: {
    color: "#FFE4E3",
    fontSize: "10px",
    marginLeft: "12px",
  },
  button: {
    width: "unset!important",
    alignSelf: "center",
  },
};
