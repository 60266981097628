export const styles = {
  input: {
    fontSize: 14,
    width: "100%",
    marginBottom: "15px",
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "14px",
    },
  },
};
