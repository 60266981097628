import React from "react";
import { NavLink } from "react-router-dom";

// Mui Components
import { Typography, Box, Button } from "@mui/material";

// Icons
import DashboardIcon from "../../../assets/icons/nav/icon@dashboard.svg";
import InfluencersIcon from "../../../assets/icons/nav/icon@influencers.svg";
import ManageTeamIcon from "../../../assets/icons/nav/icon@manage-team.svg";
import ProjectsIcon from "../../../assets/icons/nav/icon@projects.svg";
import GalleryIcon from "../../../assets/icons/nav/icon@gallery.svg";
import WeeklyIcon from "../../../assets/icons/nav/icon@weekly.svg";
import SettingsIcon from "../../../assets/icons/nav/icon@settings.svg";
import DashboardIconActive from "../../../assets/icons/nav/icon@dashboard-a.svg";
import InfluencersIconActive from "../../../assets/icons/nav/icon@influencers-a.svg";
import ManageTeamIconActive from "../../../assets/icons/nav/icon@manage-team-a.svg";
import ProjectsIconActive from "../../../assets/icons/nav/icon@projects-a.svg";
import GalleryIconActive from "../../../assets/icons/nav/icon@gallery-a.svg";
import WeeklyIconActive from "../../../assets/icons/nav/icon@weekly-a.svg";
import SettingsIconActive from "../../../assets/icons/nav/icon@settings-a.svg";

// Images
import SlackImage from "../../../assets/icons/img@slack.svg";
import JiraImage from "../../../assets/icons/img@jira.svg";

// Styles
import { styles } from "./styles";

const navigation = [
  {
    label: "Dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardIconActive,
    to: "/",
  },
  {
    label: "Influencers",
    icon: InfluencersIcon,
    activeIcon: InfluencersIconActive,
    to: "/influencers",
  },
  /*{
    label: "Manage Team",
    icon: ManageTeamIcon,
    activeIcon: ManageTeamIconActive,
    to: "/manage-teams",
  },
  {
    label: "Projects",
    icon: ProjectsIcon,
    activeIcon: ProjectsIconActive,
    to: "/projects",
  },
  {
    label: "Gallery",
    icon: GalleryIcon,
    activeIcon: GalleryIconActive,
    to: "/gallery",
  },
  {
    label: "Weekly Plan",
    icon: WeeklyIcon,
    activeIcon: WeeklyIconActive,
    to: "/weekly-plan",
  },
  {
    label: "Settings",
    icon: SettingsIcon,
    activeIcon: SettingsIconActive,
    to: "/settings",
    sub: [
      {
        label: "Faqs",
        to: "/faqs",
      },
      {
        label: "Social Medias",
        to: "/social-medias",
      },
      {
        label: "Video Links",
        to: "/video-links",
      },
      {
        label: "Contract Levels",
        to: "/contract-levels",
      },
      {
        label: "Mail Templates",
        to: "/mail-templates",
      },
    ],
  },*/
];

const Sidebar = () => {
  return (
    <Box sx={styles.sidebar}>
      <Typography sx={styles.message}>
        Influencer Management CRM Panel
      </Typography>
      <Box sx={styles.seperatorText}>MENU</Box>
      {navigation.map((item, idx) => (
        <div key={idx}>
          <NavLink
            to={item.to}
            className={(isActive) =>
              "nav-link" + (!isActive ? " unselected" : "")
            }
          >
            <Button
              variant="white"
              size="large"
              startIcon={
                <>
                  <img src={item.icon} className="normal" />
                  <img src={item.activeIcon} className="active" />
                </>
              }
              sx={styles.navigation}
            >
              {item.label}
            </Button>
          </NavLink>
          {item.sub &&
            item.sub.length > 0 &&
            item.sub.map((sub, subIdx) => {
              return (
                <Box sx={styles.linkWrap} key={subIdx}>
                  <NavLink
                    to={sub.to}
                    className={(isActive) =>
                      "nav-link" + (!isActive ? " unselected" : "")
                    }
                  >
                    {sub.label}
                  </NavLink>
                </Box>
              );
            })}
        </div>
      ))}
      <Box sx={styles.group}>
        <Box sx={styles.seperatorText}>OUTSOURCE LINKS</Box>
        <Box sx={styles.images}>
          <a href="#">
            <img src={SlackImage} />
          </a>
          <a href="#">
            <img src={JiraImage} />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
