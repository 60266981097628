export const styles = {
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  sidebar: {
    flex: "0 0 300px",
    maxWidth: "300px",
  },
  content: {
    flex: "0 0 calc(100% - 300px)",
    maxWidth: "calc(100% - 300px)",
    paddingTop: "40px",
    paddingRight: "40px",
    paddingBottom: "50px",
  },
};
