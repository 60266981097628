// Mui Components
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

// Layouts
import DashboardLayout from "../../Layouts/Dashboard";

// Components
import MainButton from "../../components/@core/button";
import Hero from "../../components/@core/hero";
import Datatable from "../../components/@core/datatable";
import Teams from "../../components/@main/teams";

// Icons
import ManageTeamIconActive from "../../assets/icons/nav/icon@manage-team-a.svg";
import InfluencerIcon from "../../assets/icons/icon@star.svg";
import AgencyIcon from "../../assets/icons/icon@agency.svg";

// Styles
import { styles } from "./styles";

import {
  nameRenderer,
  textRenderer,
  dateRenderer,
  RoleRenderer,
  TeamRenderer,
} from "../../components/@core/datatable/renderers";

function ManageTeam() {
  const tableHeads = [
    { name: "User Name", slug: "name" },
    { name: "Role", slug: "role" },
    { name: "Team", slug: "team" },
    { name: "Opening Date", slug: "opening_date" },
    { name: "Language", slug: "language" },
  ];

  const tableItems = [
    {
      name: nameRenderer("Name Surname", "https://i.pravatar.cc/34"),
      role: RoleRenderer(),
      team: TeamRenderer(),
      opening_date: dateRenderer("24.06.2022", "17:54 PM"),
      language: textRenderer("English"),
    },
    {
      name: nameRenderer("Name Surname", "https://i.pravatar.cc/34"),
      role: RoleRenderer(),
      team: TeamRenderer(),
      opening_date: dateRenderer("24.06.2022", "17:54 PM"),
      language: textRenderer("English"),
    },
    {
      name: nameRenderer("Name Surname", "https://i.pravatar.cc/34"),
      role: RoleRenderer(),
      team: TeamRenderer(),
      opening_date: dateRenderer("24.06.2022", "17:54 PM"),
      language: textRenderer("English"),
    },
    {
      name: nameRenderer("Name Surname", "https://i.pravatar.cc/34"),
      role: RoleRenderer(),
      team: TeamRenderer(),
      opening_date: dateRenderer("24.06.2022", "17:54 PM"),
      language: textRenderer("English"),
    },
  ];

  const itemTypes = [
    {
      title: "Influencer",
      icon: InfluencerIcon,
    },
    {
      title: "Agency",
      icon: AgencyIcon,
    },
  ];

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={ManageTeamIconActive} label="Manage Teams" />
        </Box>
        <Box sx={styles.buttons}>
          <Link to="/manage-teams/add-member">
            <MainButton size="large" sx={styles.secondary}>
              Add New Member
            </MainButton>
          </Link>
          <Link to="/manage-teams/create">
            <MainButton size="large" variant="contained">
              Create New Team
            </MainButton>
          </Link>
        </Box>
      </Box>

      <Teams />
      <Datatable
        headers={tableHeads}
        items={tableItems}
        types={itemTypes}
        tableLabel={"Member List"}
        tableCount={"421"}
      />
    </DashboardLayout>
  );
}

export default ManageTeam;
