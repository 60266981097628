// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../Layouts/Dashboard";

// Components
import MainButton from "../../components/@core/button";
import Hero from "../../components/@core/hero";
import Contracts from "../../components/@main/available-contracts";

// Icons
import ProjectsIconActive from "../../assets/icons/nav/icon@projects-a.svg";

// Styles
import { styles } from "./styles";

function Projects() {
  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={ProjectsIconActive} label="Projects" />
        </Box>
        <Box sx={styles.buttons}>
          <MainButton size="large" variant="contained">
            Create New Project
          </MainButton>
        </Box>
      </Box>

      <Contracts />
    </DashboardLayout>
  );
}

export default Projects;
