import _ from "lodash";
import { useNavigate } from "react-router-dom";

// Mui Components
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// Components
import Input from "../input";

// Icons
import SearchIcon from "../../../assets/icons/icon@search.svg";

// Styles
import { styles } from "./styles";

const SearchInput = (props) => {
  let navigate = useNavigate();

  const onChange = _.debounce((keyword) => {
    navigate(`/influencers?keyword=${keyword}`)
  }, 1000);

  return (
    <Box style={styles.search}>
      <Box style={styles.icon}>
        <img src={SearchIcon} />
      </Box>
      <Input
        label="Search Name, Phone, Email, @username"
        sx={styles.input}
        onChange={e => onChange(e.target.value)}
      />
    </Box>
  );
};

export default SearchInput;
