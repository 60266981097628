// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../Layouts/Dashboard";

// Components
import Hero from "../../components/@core/hero";

// Icons
import SettingsIconActive from "../../assets/icons/nav/icon@settings-a.svg";

// Styles
import { styles } from "./styles";

function Settings() {
  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={SettingsIconActive} label="Settings" />
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default Settings;
