export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subhead: {
    fontSize: "16px",
    fontWeight: 600,
  },
  summaryContainer: {
    padding: "30px 0",
  },
  summaryHero: {
    padding: "0 30px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  summaryCreateButton: {
    width: "auto!important",
    display: "flex",
    alignItems: "center",
    "& img": {
      marginLeft: "15px",
    },
  },
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "space-between",
    padding: "30px",
    marginTop: "30px",
    borderRadius: "25px",
    backgroundColor: "#F6F6F6",
    border: "solid 1px #D9D9D9",
  },
  summaryCard: {
    flex: 1,
    display: "flex",
    padding: "20px",
    gap: "15px",
    flexWrap: "wrap",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "solid 1px #E4E4E4",
    borderRadius: "15px",
    "& img": {
      display: "block",
    },
  },
  summaryCardDetail: {
    paddingLeft: "20px",
  },
  summaryCardValue: {
    fontSize: "22px",
    fontWeight: 600,
  },
  summaryCardLabel: {
    color: "#A7A7A7",
    fontSize: "10px",
  },
  calendarWrapper: {
    padding: "30px",
    marginTop: "30px",
    borderRadius: "25px",
    backgroundColor: "#F6F6F6",
    border: "solid 1px #D9D9D9",
  },
};
