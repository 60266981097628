export const styles = {
  container: {
    marginTop: "50px",
  },
  label: {
    fontSize: "16px",
    color: "#000000",
    fontWeight: 600,
    paddingLeft: "30px",
    marginBottom: "20px",
  },
  content: {
    padding: "30px",
    borderRadius: "25px",
    backgroundColor: "#F6F6F6",
    border: "solid 1px #D9D9D9",
  },
  transparent: {
    padding: 0,
    border: "unset",
    backgroundColor: "transparent",
    borderRadius: 0,
  },
  count: {
    display: "inline-block",
    width: "27px",
    height: "27px",
    marginLeft: '12px',
    color: "#fff",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    lineHeight: "27px",
    backgroundColor: "#E02B26",
    borderRadius: "100%",
  },
};
