import { request } from "../axios";

export const auth = {
  post_login: (params) => {
    return request.post("/auth/login", params);
  },
  get_auth_google: (params) => {
    return request.get("/auth/google", params);
  },  
  get_oauth_callback: (params) => {
    return request.get("/auth/google/oauth-callback", params);
  },  
};