import { request } from "../axios";

export const faqs = {
  get_faqs: (params, pId, lang) => {
    return request.get(`/faqs/${pId}/${lang}`, params);
  },
  add_faq: (params) => {
    return request.post(`/faqs`, params);
  },
  update_faq: (params, id) => {
    return request.put(`/faqs/${id}`, params);
  },
  delete_faq: (params, id) => {
    return request.delete(`/faqs/${id}`, params);
  },
  find_faq: (params, id) => {
    return request.get(`/faqs/findOne/${id}`, params);
  },  
};
