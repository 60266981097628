export const styles = {
  search: {
    position: "relative",
  },
  icon: {
    position: "absolute",
    left: "15px",
    top: "50%",
    marginTop: "3px",
    transform: "translateY(-50%)",
  },
  input: {
    width: "510px",
    marginBottom: 0,
    "& .MuiOutlinedInput-input": {
      paddingLeft: "45px",
    },
    "& .MuiInputLabel-root": {
      left: "35px",
    },
    "& .MuiInputLabel-root.Mui-focused": {
        left: "10px",
      },    
  },
};
