import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../Layouts/Dashboard";

// Components
import MainButton from "../../components/@core/button";
import Hero from "../../components/@core/hero";
import Stats from "../../components/@main/stats";
import AvailableProjects from "../../components/@main/available-projects";
import RecentlyAddedPosts from "../../components/@main/recently-added-posts";
import RecentlySignedUsers from "../../components/@main/recently-signed-users";

// Icons
import DashboardIconActive from "../../assets/icons/nav/icon@dashboard-a.svg";

// Services
import { stats } from "../../services/stats";
import { projects } from "../../services/projects";
import { users } from "../../services/users";

// Styles
import { styles } from "./styles";

function Dashboard() {
  const [statsData, setStats] = useState(null);
  const [projectsData, setProjects] = useState(null);
  const [usersData, setUsers] = useState(null);

  useEffect(() => {
    stats.get_stats().then((res) => {
      setStats(res.data);
    });
    projects.get_projects().then((res) => {
      setProjects(res.data);
    });
    users.get_users({ page: 1 }).then((res) => {
      setUsers(res.data.items);
    });
  }, []);

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={DashboardIconActive} label="Dashboard" />
        </Box>
        {/*
        <Box>
          <MainButton size="large" variant="contained">
            Download Excel
          </MainButton>
  </Box>*/}
      </Box>
      {statsData && <Stats stats={statsData} />}
      {projectsData && <AvailableProjects projects={projectsData} />}
      <Box sx={styles.multiCol}>
        <Box sx={styles.col}>
          {usersData && <RecentlySignedUsers users={usersData} />}
        </Box>
        {/*        
        <Box sx={styles.col}>
          <RecentlyAddedPosts />
</Box>*/}
      </Box>
      <Link to="/influencer/add">
        <MainButton sx={styles.addInfluencer} size="large" variant="contained">
          Add Influencer
        </MainButton>{" "}
      </Link>
    </DashboardLayout>
  );
}

export default Dashboard;
