import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

// Mui Components
import { Box, Link, Typography, IconButton, Avatar } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import DropdownButtonIcon from "../../../components/@core/dropdown-button-icon";

// Icons
import InfluencersIconActive from "../../../assets/icons/nav/icon@influencers-a.svg";
import IconBell from "../../../assets/icons/icon@bell.svg";
import IconMessage from "../../../assets/icons/icon@message.svg";
import IconTrashBlack from "../../../assets/icons/icon@trash-black.svg";
import IconRegistered from "../../../assets/icons/icon@registered.svg";
import IconChecked from "../../../assets/icons/icon@checked.svg";
import IconClose from "../../../assets/icons/icon@close.svg";
import IconWarning from "../../../assets/icons/icon@warning.svg";
import IconContract from "../../../assets/icons/icon@contract.svg";
import IconReferral from "../../../assets/icons/icon@referrals.svg";

// Page Components
import SocialAccounts from "./components/social-accounts";
import ProfileDetails from "./components/profile-details";
import ActiveContracts from "./components/active-contracts";
import WaitingApproval from "./components/waiting-approval";
import WaitingRequests from "./components/waiting-requests";
import LatestPosts from "./components/latest-posts";
import Files from "./components/files";

// Services
import { users } from "../../../services/users";

// Utils
import { sleep } from "../../../utils/sleep";

// Styles
import { styles, StyledMenu } from "./styles";

function InfluencerDetail() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [influencer, setInfo] = useState(null);
  const [isLoadingFailed, setLoadingFailed] = useState(false);

  useEffect(() => {
    handlePageValues();
  }, [location]);

  const handlePageValues = () => {
    let id = location.pathname.split("/influencer/")[1];

    getInfluencerDetails(id);
  };

  const getInfluencerDetails = (id) => {
    const getUserDetail = async () => {
      let { data: influencerData } = await users.get_user({ id: id });
      let { data: socialMediaData } = await users.get_social_medias({ id: id });
      let { data: contractData } = await users.get_user_contracts({ id: id });
      return {
        info: influencerData,
        social: socialMediaData,
        contracts: contractData,
      };
    };

    toast.promise(getUserDetail(), {
      loading: "Influencer details loading.",
      success: (response) => {
        setInfo(response);
        return `Influencer details loaded.`;
      },
      error: () => {
        setLoadingFailed(true);

        return `Influencer details can't loaded.`;
      },
    });
  };

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={InfluencersIconActive} label="Influencer Profile" />
        </Box>
      </Box>

      {influencer ? (
        <>
          <ContentCard label="Profile Details">
            <Box sx={styles.influencerSummary}>
              <Box>
                <Box sx={styles.nameContainer}>
                  <Box sx={styles.nameAvatar}>
                    <Avatar>{influencer.info.firstName.substring(0, 1)}</Avatar>
                    {/*<Box sx={styles.nameCount}>2</Box>*/}
                  </Box>
                  <Box>
                    <Typography sx={styles.influencerName}>
                      {influencer.info.firstName} {influencer.info.lastName}
                    </Typography>
                    <Typography sx={styles.influencerSubHero}>
                      {influencer.info.userType
                        ? influencer.info.userType.toUpperCase()
                        : null}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ marginLeft: "20px", marginRight: "20px" }}>
                {/*
              <DropdownButtonIcon
                id="registered-button"
                aria-controls={open ? "registered-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                prevIcon={IconRegistered}
                sx={styles.dbutton}
                size="large"
              >
                Registered
              </DropdownButtonIcon>
              <StyledMenu
                id="registered-menu"
                MenuListProps={{
                  "aria-labelledby": "registered-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} disableRipple>
                  <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                  <FileCopyIcon />
                  Duplicate
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                  <ArchiveIcon />
                  Archive
                </MenuItem>
              </StyledMenu>*/}
              </Box>
              {/*
              <Box sx={styles.buttons}>
                <IconButton aria-label="messages" sx={styles.button}>
                  <img src={IconBell} />
                </IconButton>
                <IconButton aria-label="messages" sx={styles.button}>
                  <img src={IconMessage} />
                </IconButton>
                <IconButton aria-label="messages" sx={styles.button}>
                  <img src={IconTrashBlack} />
                </IconButton>
            </Box>*/}
              <Box sx={styles.influencerValues}>
                <Box>
                  <Box sx={styles.valueLabel}>CONTRACT VALUE</Box>
                  <Box sx={styles.valueDetail}>0$</Box>
                </Box>
                <Box>
                  <Box sx={styles.valueLabel}>REFERRED VALUE</Box>
                  <Box sx={styles.valueDetail}>0$</Box>
                </Box>
                <Box>
                  <Box sx={styles.valueLabel}>TOTAL VALUE</Box>
                  <Box sx={[styles.valueDetail, styles.valueDetailRed]}>0$</Box>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.influencerSummaryStats}>
              <Box sx={styles.influencerSummaryStat}>
                <img src={IconChecked} alt="" />
                <Box>
                  <Box sx={styles.statValue}>0</Box>
                  <Box sx={styles.statDesc}>SHARED POSTS</Box>
                </Box>
              </Box>
              <Box sx={styles.influencerSummaryStat}>
                <img src={IconClose} alt="" />
                <Box>
                  <Box sx={styles.statValue}>0</Box>
                  <Box sx={styles.statDesc}>NOT-SHARED POSTS</Box>
                </Box>
              </Box>
              <Box sx={styles.influencerSummaryStat}>
                <img src={IconWarning} alt="" />
                <Box>
                  <Box sx={styles.statValue}>0</Box>
                  <Box sx={styles.statDesc}>WAITING POSTS</Box>
                </Box>
              </Box>
              <Box sx={styles.influencerSummaryStat}>
                <img src={IconContract} alt="" />
                <Box>
                  <Box sx={styles.statValue}>0</Box>
                  <Box sx={styles.statDesc}>ACTIVE CONTRATS</Box>
                </Box>
              </Box>
              <Box sx={styles.influencerSummaryStat}>
                <img src={IconReferral} alt="" />
                <Box>
                  <Box sx={styles.statValue}>0</Box>
                  <Box sx={styles.statDesc}>REFERRALS</Box>
                </Box>
              </Box>
            </Box>
          </ContentCard>
          {influencer && influencer.social.length > 0 && <SocialAccounts />}
          {influencer && (
            <ProfileDetails
              info={influencer.info}
              reload={() => handlePageValues()}
            />
          )}
          {influencer && influencer.contracts.length > 0 && (
            <ActiveContracts contracts={influencer.contracts} />
          )}
          {/*
      <WaitingApproval />
      <WaitingRequests />
            <LatestPosts />*/}
          {/*<Files />*/}
        </>
      ) : (
        <>{isLoadingFailed && <p>Can't loaded influencer details.</p>}</>
      )}
    </DashboardLayout>
  );
}

export default InfluencerDetail;
