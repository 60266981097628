export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subLabel: {
    fontSize: "16px",
    fontWeight: 600,
    paddingLeft: "30px",
    marginTop: "40px",
  },
  cols: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  col: {
    flex: "0 0 49%",
    maxWidth: "49",
  },
  fieldLabel: {
    fontSize: "16px",
    fontWeight: 600,
  },
  fieldDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginTop: "10px",
  },
  fileItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "20px",
    marginTop: "20px",
    backgroundColor: "#fff",
    border: "dashed 2px #E4E4E4",
    borderRadius: "6px",
    "& .MuiButton-root": {
      width: "auto!important",
    },
    "& > img": {
      marginRight: "20px",
    },
    "& > .MuiButton-root": {
      marginLeft: "auto",
    },
    "& > div:nth-of-type(3)": {
      marginLeft: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    "& > div:nth-of-type(3) .MuiButton-root": {
      alignSelf: "stretch",
      marginLeft: "15px",
    },
  },
  fileLabel: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#000",
  },
  fileDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginTop: "2px",
  },
  fileViewButton: {
    color: "#000",
    border: "solid 1px #E4E4E4",
    backgroundColor: "#F6F6F6",
    "&.MuiButton-root:hover": {
      backgroundColor: "#ccc",
    },
  },
  uploadButton: {
    color: "#000",
    border: "solid 1px #E4E4E4",
    backgroundColor: "#F6F6F6",
    "&.MuiButton-root:hover": {
      backgroundColor: "#ccc",
    },
  },
  multiInput: {
    marginTop: "20px",
    backgroundColor: "#fff!important",
  },
  socialMedias: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: "25px",
  },
  socialMediaItem: {
    cursor: "pointer",
    marginRight: "10px",
  },
  continueArea: {
    paddingTop: "25px",
    "& .MuiButton-root": {
      width: "auto!important",
    },
  },
};
