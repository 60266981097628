import React from "react";

// Mui Components
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Components
import MainButton from "../button";
import DropdownButton from "../dropdown-button";

// Icons
import RegisteredIcon from "../../../assets/icons/icon@registered.svg";
import PowerAdminIcon from "../../../assets/icons/icon@power-admin.svg";
import TrashIcon from "../../../assets/icons/icon@trash.svg";
import HeadIcon from "../../../assets/icons/icon@head.svg";
import CheckedIcon from "../../../assets/icons/icon@checked.svg";
import ErrorIcon from "../../../assets/icons/icon@close.svg";
import WarningIcon from "../../../assets/icons/icon@warning.svg";

// Constants
import { contractedStatuses, roles } from "./constants";

// Styles
import { styles, StyledMenu } from "./styles";

export const textRenderer = (text) => (
  <Box>
    <Typography sx={styles.tableText}>{text}</Typography>
  </Box>
);

export const textWithImageRenderer = () => (
  <Box>
    <img src="" alt="" />
    <Typography sx={styles.tableText}>Lorem</Typography>
  </Box>
);

export const buttonRenderer = () => <Box>button</Box>;

export const logoRenderer = (src) => (
  <Box sx={styles.logoContainer}>
    <img src={src} alt="" />
  </Box>
);

export const nameRenderer = (name, avatar) => (
  <Box sx={styles.nameContainer}>
    <Box sx={styles.nameAvatar}>
      <img src={avatar} alt="" style={styles.avatarImg} />
    </Box>
    <Typography sx={styles.influencerName}>{name}</Typography>
  </Box>
);

export const influencerNameRenderer = (name, avatar) => (
  <Box sx={styles.nameContainer}>
    {avatar ? (
      <Box sx={styles.nameAvatar}>
        <img src={HeadIcon} alt="" style={styles.avatarBadge} />
        <img src={avatar} alt="" style={styles.avatarImg} />
      </Box>
    ) : (
      <Avatar>{name.substring(0, 1)}</Avatar>
    )}
    <Typography sx={styles.influencerName}>{name}</Typography>
  </Box>
);

export const postStatusRenderer = (valid, error, warned) => (
  <Box sx={styles.statusItems}>
    <Box sx={styles.statusItem}>
      <Typography sx={styles.statusText}>{valid}</Typography>
      <img src={CheckedIcon} alt="" />
    </Box>
    <Box sx={styles.statusItem}>
      <Typography sx={styles.statusText}>{error}</Typography>
      <img src={ErrorIcon} alt="" />
    </Box>
    <Box sx={styles.statusItem}>
      <Typography sx={styles.statusText}>{warned}</Typography>
      <img src={WarningIcon} alt="" />
    </Box>
  </Box>
);

export const dateRenderer = (date, time) => (
  <Box>
    <Typography sx={styles.dateText}>{date}</Typography>
    <Typography sx={styles.dateHours}>{time}</Typography>
  </Box>
);

export const ContractedStatusRenderer = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <DropdownButton
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={styles.dbutton}
        size="large"
      >
        <img src={RegisteredIcon} alt="" style={styles.dropdownIcon} />
        Registered
      </DropdownButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {contractedStatuses.map((item) => (
          <MenuItem onClick={handleClose} disableRipple>
            <img src={item.icon} alt="" style={styles.dropdownIcon} />
            {item.title}
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};

export const RoleRenderer = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <DropdownButton
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={styles.dbutton}
        size="large"
      >
        <img src={PowerAdminIcon} alt="" style={styles.dropdownIcon} />
        Power Admin
      </DropdownButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {roles.map((item) => (
          <MenuItem onClick={handleClose} disableRipple>
            <img src={item.icon} alt="" style={styles.dropdownIcon} />
            {item.title}
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};

export const TeamRenderer = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <DropdownButton
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={styles.dbutton}
        size="large"
      >
        N/A
      </DropdownButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          Team 01
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          Team 02
        </MenuItem>
      </StyledMenu>
    </Box>
  );
};

export const OpenerRenderer = (user, openers, handleOpenerChange) => {
  let selectedValue = openers.filter(i => i.id == user.openerId)
  return (
    <Box sx={styles.cellDropdown}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">Opener</InputLabel>
        <Select
          label="Opener"
          value={selectedValue.length > 0 ? selectedValue[0].id : ""}
          onChange={(e) => handleOpenerChange(user, e.target.value)}
        >
          <MenuItem value={""}>Unset</MenuItem>
          {openers.map((item, key) => {
            return (
              <MenuItem value={item.id}>
                {item.firstName} {item.lastName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export const dropdownRenderer = () => <Box>dropdown</Box>;

export const contentRenderer = () => (
  <Box>
    <img src="" alt="" />
    <Typography>lorem</Typography>
  </Box>
);

export const platformRenderer = () => <Box>icon</Box>;

export const imageRenderer = () => <Box>icon</Box>;

export const statusRenderer = () => (
  <Box>
    <Box />
    <Typography>lorem</Typography>
  </Box>
);

export const userWithCheckboxRenderer = () => (
  <Box>
    checkbox
    <Box>
      <img src="" alt="" />
      <Typography>lorem</Typography>
    </Box>
  </Box>
);

export const actionsRenderer = () => (
  <Box sx={styles.actions}>
    <MainButton
      variant="contained"
      aria-label="edit-item"
      sx={styles.actionButton}
    >
      Edit
    </MainButton>
    <MainButton
      variant="contained"
      aria-label="remove-item"
      sx={styles.actionButton}
    >
      <img src={TrashIcon} />
    </MainButton>
  </Box>
);
