import React, { useEffect, useState } from "react";

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import Stats from "../../../components/@main/stats";
import WaitingApproval from "../../../components/@main/waiting-approval";
import Datatable from "../../../components/@core/datatable";

// Icons
import CoverImage from "../../../assets/images/img@cover.png";
import HuhLogo from "../../../assets/images/img@huh-logo.png";
import ProjectsIconActive from "../../../assets/icons/nav/icon@projects-a.svg";
import InfluencerIcon from "../../../assets/icons/icon@star.svg";
import AgencyIcon from "../../../assets/icons/icon@agency.svg";

// Styles
import { styles } from "./styles";

// Services
import { stats } from "../../../services/stats";
import { users } from "../../../services/users";

// Utils
import moment from "moment";

import {
  influencerNameRenderer,
  postStatusRenderer,
  dateRenderer,
} from "../../../components/@core/datatable/renderers";


function ContractDetail() {
  const [statsData, setStats] = useState(null);

  const tableHeads = [
    { name: "Name", slug: "name" },
    { name: "Post Status", slug: "post_status" },
    { name: "Account Date", slug: "account_date" },
    { name: "Contracted Status", slug: "contracted_status" },
    { name: "Opener", slug: "opener" },
  ];

  const itemTypes = [
    {
      title: "Influencer",
      icon: InfluencerIcon,
    },
    {
      title: "Agency",
      icon: AgencyIcon,
    },
  ];
  const [usersData, setUsers] = useState([]);

  useEffect(() => {
    users.get_users().then((res) => {
      if (res.data) {
        res.data.map((user) => {
          let item = {
            name: influencerNameRenderer(
              `${user.firstName} ${user.lastName}`,
              "https://i.pravatar.cc/34"
            ),
            post_status: postStatusRenderer(0, 0, 0),
            account_date: dateRenderer(
              moment(user.createdDate).format("MM.DD.YYYY"),
              moment(user.createdDate).format("HH:mm A")
            ),
          };
          //setUsers(currentArray => [...currentArray, item]);
          setUsers([]);
        });
      }
    });
  }, []);

  useEffect(() => {
    stats.get_stats().then((res) => {
      setStats(res.data);
    });
  }, []);

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={ProjectsIconActive} label="Contracts" />
        </Box>
        <Box sx={styles.buttons}>
          <MainButton size="large" variant="contained">
            Create New Project
          </MainButton>
        </Box>
      </Box>

      <Box sx={styles.coverHero}>
        <img src={CoverImage} />
        <Box sx={styles.coverInfo}>
          <img src={HuhLogo} alt="" />
          <Box sx={styles.col}>
            <Box sx={styles.name}>Lanistar Ltd Contract</Box>
            <Box sx={styles.desc}>ONLINE CHALLENGER BANK</Box>
          </Box>
          <Box sx={[styles.col, { marginLeft: 'auto'}]}>
            <Box sx={styles.label}>3.521</Box>
            <Box sx={styles.value}>INFLUENCER JOINED</Box>
          </Box>
          <Box sx={styles.col}>
            <Box sx={styles.label}>550.000$</Box>
            <Box sx={styles.value}>CONTRACT VALUE</Box>
          </Box>
          <Box sx={styles.col}>
            <Box sx={styles.label}>240.000</Box>
            <Box sx={styles.value}>FOLLOWER NEEDED</Box>
          </Box>
        </Box>
      </Box>

      {statsData && <Stats stats={statsData} />}

      <Datatable
        headers={tableHeads}
        items={usersData}
        types={itemTypes}
        tableLabel={"Contract Signed Influencers"}
        tableCount={"192317"}
      />      

      <WaitingApproval />
      
    </DashboardLayout>
  );
}

export default ContractDetail;
