import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// Mui Components
import { Typography, Box, Avatar, IconButton } from "@mui/material";

// Components
import SearchInput from "../../@core/search-input";

// Images
import Logo from "../../../assets/images/logo@wpro.svg";

// Icons
import IconBell from "../../../assets/icons/icon@bell.svg";
import IconMessage from "../../../assets/icons/icon@message.svg";
import LogoutIcon from "@mui/icons-material/Logout";

// Styles
import { styles, StyledBadge, StyledNotice } from "./styles";

// Store
import { authStore } from "../../../store/auth";

import { parseJwt } from "../../../utils/decode";

const Header = () => {
  let navigate = useNavigate();
  const setLogin = authStore((state) => state.updateAuthStatus);
  const setUserTokens = authStore((state) => state.setUserTokens);
  const userToken = authStore((state) => state.userTokens);

  const [userDetails, setDetails] = useState(null);

  const logout = () => {
    setLogin(false);
    setUserTokens(null);

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  useEffect(() => {
    if (userToken && userToken.accessToken) {
      setDetails(parseJwt(userToken.accessToken));
    }
  }, [userToken]);

  return (
    <Box sx={styles.header}>
      <Box sx={styles.logo}>
        <Link to={"/"}>
          <img src={Logo} alt="WPRO" />
        </Link>
      </Box>
      <Box sx={styles.content}>
        <Box sx={styles.searchWrap}>
          <SearchInput />
        </Box>
        <Box sx={styles.buttons}>
          <StyledNotice
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            variant="dot"
          >
            <IconButton aria-label="notifications" sx={styles.button}>
              <img src={IconBell} />
            </IconButton>
          </StyledNotice>
          <IconButton aria-label="messages" sx={styles.button}>
            <img src={IconMessage} />
          </IconButton>
          {userDetails && (
            <Box sx={styles.userWrap}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                variant="dot"
              >
                <Avatar sx={styles.avatar}>
                  {userDetails.name.substring(0, 1)}
                </Avatar>
              </StyledBadge>
              <Box>
                <Typography sx={styles.welcome}>
                  Hi, {userDetails.name}
                </Typography>
                <Typography sx={styles.title}>
                  {userDetails.typ.toUpperCase()}
                </Typography>
              </Box>
            </Box>
          )}
          <Box sx={styles.logout} onClick={() => logout()}>
            <LogoutIcon />
            Logout
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
