// Mui Components
import { CardActionArea, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Components
import ContentCard from "../../@core/content-card";

// Icons
import RegisteredIcon from "../../../assets/icons/icon@registered.svg";
import ReferredIcon from "../../../assets/icons/icon@referred.svg";
import RejectedIcon from "../../../assets/icons/icon@rejected.svg";
import WaitingIcon from "../../../assets/icons/icon@waiting.svg";
import AwaitingIcon from "../../../assets/icons/icon@awaiting.svg";
import BookedIcon from "../../../assets/icons/icon@booked.svg";
import DidntIcon from "../../../assets/icons/icon@didnt.svg";
import UncontractIcon from "../../../assets/icons/icon@uncontract.svg";
import SignedIcon from "../../../assets/icons/icon@signed.svg";
import CancelledIcon from "../../../assets/icons/icon@cancelled.svg";

// Styles
import { styles } from "./styles";

const Stats = props => {
  const { stats } = props;
  const navigate = useNavigate();

  const values = [
    {
      icon: RegisteredIcon,
      name: "New Leads",
      value: 0,
      link: "/influencers?contractStatus=registered"
    },    
    {
      icon: SignedIcon,
      name: "Contract Signed",
      value: stats[3].total,
      link: "/influencers?contractStatus=signed"
    },   
    {
      icon: AwaitingIcon,
      name: "Contract Not Signed",
      value: 0,
      link: "/influencers?contractStatus=notSigned"
    }, 
    {
      icon: UncontractIcon,
      name: "Expired Contracts",
      value: 0,
      link: "/influencers?contractStatus=expired"
    },        
    {
      icon: CancelledIcon,
      name: "Cancelled Contracts",
      value: 0,
      link: "/influencers?contractStatus=cancelled"
    },    
    {
      icon: ReferredIcon,
      name: "Referred",
      value: stats[9].total,
      link: "/influencers?contractStatus=referred"
    },   
    {
      icon: DidntIcon,
      name: "Special Contracts",
      value: 0,
      link: "/influencers?contractStatus=special"
    },     
  ];

  return (
    <ContentCard label="Stats">
      <Box sx={styles.items}>
        {values.map((item, idx) => {
          return (
            <CardActionArea sx={styles.item} key={idx} onClick={() => navigate(item.link)}>
              <Box sx={styles.icon}>
                <img src={item.icon} alt={item.name} />
              </Box>
              <Box sx={styles.text}>
                <Typography sx={styles.label}>{item.name}</Typography>
                <Typography sx={styles.value}>{item.value}</Typography>
              </Box>
            </CardActionArea>
          );
        })}
      </Box>
    </ContentCard>
  );
};

export default Stats;
