export const styles = {
  button: {
    boxShadow: "unset!important",
    width: "100%!important",
    textTransform: 'unset!important',
    color: '#000',
    backgroundColor: '#fff',
    border: 'solid 1px #E4E4E4',
    paddingLeft: '15px',
    '&.MuiButton-root img': {
      height: '22px',
      marginRight: '10px'
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#f1f1f1'
    }
  },
};
