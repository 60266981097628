export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  multiCol: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  col: {
    flex: "0 0 49%",
    maxWidth: "49%"
  },
  addInfluencer: {
    marginTop: "50px",
    maxWidth: "170px",
    backgroundColor: "#E02B26",
  },
};
