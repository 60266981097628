export const styles = {
  items: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  item: {
    flex: "0 0 32.3%",
    maxWidth: "32.3%",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: '25px',
    padding: "15px 25px",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "solid 1px #E4E4E4",
    borderRadius: "6px",
    justifyContent: 'flex-start',
    
    "&:last-of-type": {
      marginBottom: 0
    }
  },
  icon: {
    marginRight: "25px",
  },
  text: {},
  label: {
    color: "#A7A7A7",
    fontSize: "14px",
  },
  value: {
    color: "#000",
    fontSize: "28px",
    fontWeight: 600,
  },
};
