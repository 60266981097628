import React, { useState } from "react";

// Mui Components
import { Box, InputAdornment } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import Input from "../../../components/@core/input";
import MainButton from "../../../components/@core/button";

// Icons
import IconUpload from "../../../assets/icons/icon@upload.svg";
import IconRemove from "../../../assets/icons/icon@remove.svg";
import ProjectsIconActive from "../../../assets/icons/nav/icon@projects-a.svg";
import IconFacebook from "../../../assets/icons/icon@facebook-circle.svg";
import IconTwitter from "../../../assets/icons/icon@twitter-circle.svg";
import IconInstagram from "../../../assets/icons/icon@instagram-circle.svg";
import IconLinkedin from "../../../assets/icons/icon@linkedin-circle.svg";

// Styles
import { styles } from "./styles";

function CreateProject() {
  const [step, setStep] = useState(1);

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={ProjectsIconActive} label="Projects" />
        </Box>
      </Box>

      <Box sx={styles.subLabel}>Creating New Project</Box>

      <Box sx={styles.stepContainer}>
        <Box sx={[styles.stepItem, step === 1 ? styles.stepItemActive : null]}>
          1
        </Box>
        <Box sx={[styles.stepItem, step === 2 ? styles.stepItemActive : null]}>
          2
        </Box>
        <Box sx={[styles.stepItem, step === 3 ? styles.stepItemActive : null]}>
          3
        </Box>
        <Box sx={[styles.stepItem, step === 4 ? styles.stepItemActive : null]}>
          4
        </Box>
        <Box sx={[styles.stepItem, step === 5 ? styles.stepItemActive : null]}>
          5
        </Box>
        <Box sx={[styles.stepItem, step === 6 ? styles.stepItemActive : null]}>
          6
        </Box>
      </Box>

      {step === 1 && (
        <>
          <ContentCard>
            <Box sx={styles.fieldContainer}>
              <Box sx={styles.fieldLabel}>
                1. Set the project name and description
              </Box>
              <Box sx={styles.fieldDesc}>
                Higher the number, will be better.
              </Box>
              <Input sx={styles.singleInput} label="Project name" />
              <Input
                sx={styles.multiInput}
                label="Description"
                multiline
                rows={4}
              />
            </Box>
          </ContentCard>
          <Box sx={styles.continueArea}>
            <MainButton
              size="large"
              variant="contained"
              onClick={() => setStep(2)}
            >
              Next
            </MainButton>
          </Box>
        </>
      )}

      {step === 2 && (
        <>
          <ContentCard>
            <Box sx={styles.fieldContainer}>
              <Box sx={styles.fieldLabel}>2. How much follower needed?</Box>
              <Box sx={styles.fieldDesc}>
                Select amount of followers needed to sign this contract.
              </Box>
              <Input sx={styles.singleInput} label="Select" type="number" />
            </Box>
          </ContentCard>
          <Box sx={[styles.continueArea, styles.continueAreaSpace]}>
            <MainButton
              sx={styles.secondary}
              size="large"
              variant="contained"
              onClick={() => setStep(1)}
            >
              Back
            </MainButton>
            <MainButton
              size="large"
              variant="contained"
              onClick={() => setStep(3)}
            >
              Next
            </MainButton>
          </Box>
        </>
      )}

      {step === 3 && (
        <>
          <ContentCard>
            <Box sx={styles.cols}>
              <Box sx={styles.col}>
                <Box sx={styles.fieldContainer}>
                  <Box sx={styles.fieldLabel}>
                    3. Type a sharing value of this contract
                  </Box>
                  <Box sx={styles.fieldDesc}>
                    Total amounf of this contract can provide.
                  </Box>
                  <Input
                    sx={styles.singleInput}
                    label="Type Amount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box sx={styles.col}>
                <Box sx={styles.fieldContainer}>
                  <Box sx={styles.fieldLabel}>
                    4. Which platforms is this deal valid for?
                  </Box>
                  <Box sx={styles.fieldDesc}>Select a platform or few.</Box>
                  <Box sx={styles.socialMedias}>
                    <Box sx={styles.socialMediaItem}>
                      <img src={IconInstagram} alt="" />
                    </Box>
                    <Box sx={styles.socialMediaItem}>
                      <img src={IconTwitter} alt="" />
                    </Box>
                    <Box sx={styles.socialMediaItem}>
                      <img src={IconFacebook} alt="" />
                    </Box>
                    <Box sx={styles.socialMediaItem}>
                      <img src={IconLinkedin} alt="" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ContentCard>
          <Box sx={[styles.continueArea, styles.continueAreaSpace]}>
            <MainButton
              sx={styles.secondary}
              size="large"
              variant="contained"
              onClick={() => setStep(2)}
            >
              Back
            </MainButton>
            <MainButton
              size="large"
              variant="contained"
              onClick={() => setStep(4)}
            >
              Next
            </MainButton>
          </Box>
        </>
      )}

      {step === 4 && (
        <>
          <ContentCard>
            <Box sx={styles.fieldContainer}>
              <Box sx={styles.fieldLabel}>
                5. Upload contract information video.
              </Box>
              <Box sx={styles.fieldDesc}>
                Total amounf of this contract can provide.
              </Box>
              <Box sx={styles.fileItem}>
                <img src={IconUpload} alt="" />
                <Box>
                  <Box sx={styles.fileLabel}>Drag or Upload</Box>
                  <Box sx={styles.fileDesc}>FILE TYPE (MAX: SIZE)</Box>
                </Box>
                <MainButton
                  sx={styles.uploadButton}
                  size="large"
                  variant="contained"
                >
                  Upload
                </MainButton>
              </Box>
              <Input sx={styles.singleInput} label="Project name" />
              <Input
                sx={styles.multiInput}
                label="Description"
                multiline
                rows={4}
              />
            </Box>
          </ContentCard>
          <Box sx={[styles.continueArea, styles.continueAreaSpace]}>
            <MainButton
              sx={styles.secondary}
              size="large"
              variant="contained"
              onClick={() => setStep(3)}
            >
              Back
            </MainButton>
            <MainButton
              size="large"
              variant="contained"
              onClick={() => setStep(5)}
            >
              Next
            </MainButton>
          </Box>
        </>
      )}

      {step === 5 && (
        <>
          <ContentCard>
            <Box sx={styles.fieldContainer}>
              <Box sx={styles.fieldLabel}>
                6. All contracts must have an NDA
              </Box>
              <Box sx={styles.fieldDesc}>Paste an NDA document bellow</Box>
              <Input sx={styles.singleInput} label="NDA Tittle" />
              <Input
                sx={styles.multiInput}
                label="Paste an NDA"
                multiline
                rows={4}
              />
            </Box>
          </ContentCard>
          <Box sx={[styles.continueArea, styles.continueAreaSpace]}>
            <MainButton
              sx={styles.secondary}
              size="large"
              variant="contained"
              onClick={() => setStep(4)}
            >
              Back
            </MainButton>
            <MainButton
              size="large"
              variant="contained"
              onClick={() => setStep(6)}
            >
              Next
            </MainButton>
          </Box>
        </>
      )}

      {step === 6 && (
        <>
          <ContentCard>
            <Box sx={styles.fieldContainer}>
              <Box sx={styles.fieldLabel}>7. Contract F.A.Q.</Box>
              <Box sx={styles.fieldDesc}>Paste an NDA document bellow</Box>
              <Box sx={styles.questionItem}>
                <Box sx={styles.questionLabel}>
                  Question 1
                  <Box sx={styles.questionRemove}>
                    <img src={IconRemove} alt="" />
                  </Box>
                </Box>
                <Input sx={styles.singleInput} label="Question" />
                <Input
                  sx={styles.multiInput}
                  label="Answer"
                  multiline
                  rows={4}
                />
              </Box>
              <Box sx={styles.questionItem}>
                <Box sx={styles.questionLabel}>
                  Question 2
                  <Box sx={styles.questionRemove}>
                    <img src={IconRemove} alt="" />
                  </Box>
                </Box>
                <Input sx={styles.singleInput} label="Question" />
                <Input
                  sx={styles.multiInput}
                  label="Answer"
                  multiline
                  rows={4}
                />
              </Box>

              <MainButton
                size="large"
                variant="contained"
                sx={styles.addQuestion}
              >
                +
              </MainButton>
            </Box>
          </ContentCard>
          <Box sx={[styles.continueArea, styles.continueAreaSpace]}>
            <MainButton
              sx={styles.secondary}
              size="large"
              variant="contained"
              onClick={() => setStep(5)}
            >
              Back
            </MainButton>
            <MainButton
              size="large"
              variant="contained"
              onClick={() => alert("create")}
            >
              Create New Project
            </MainButton>
          </Box>
        </>
      )}
    </DashboardLayout>
  );
}

export default CreateProject;
