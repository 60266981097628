import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const styles = {
  table: {
    padding: "30px",
    border: "solid 1px #D9D9D9",
    borderRadius: "25px",
  },
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: "12px",
  },
  headCol: {
    flex: "0 0 33.33%",
    maxWidth: "33.33%",
  },
  headText: {
    fontSize: "10px",
    color: "#A7A7A7",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "9px",
    paddingBottom: "9px",
    cursor: "pointer"
  },
  rowCol: {
    flex: "0 0 33.33%",
    maxWidth: "33.33%",
  },
  item: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  left: {
    marginRight: "12px",
    "& img": {
      display: "block",
      width: "100%",
    },
  },
  label: {
    color: "#1F1F1F",
    fontSize: "14px",
    fontWeight: 600,
  },
  dateLabel: {
    color: "#1F1F1F",
    fontSize: "14px",
    fontWeight: 600,
  },
  hour: {
    color: "#A7A7A7",
    fontSize: "10px",
  },
  dbutton: {
    color: "#000",
    justifyContent: "space-between",
    border: "solid 1px #E4E4E4",
    backgroundColor: "transparent!important",
  },
};
