import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Routes
import Login from "../containers/Login";

import Dashboard from "../containers/Dashboard";
import Settings from "../containers/Settings";

// Influencer Pages
import Influencers from "../containers/Influencers";
import InfluencerDetail from "../containers/Influencers/InfluencerDetail";
import InfluencerAdd from "../containers/Influencers/InfluencerAdd";
import InviteInfluencer from "../containers/Influencers/InfluencerInvite";
import AgentAdd from "../containers/Influencers/AgentAdd";
import AgentDetail from "../containers/Influencers/AgentDetail";

// Team Pages
import ManageTeam from "../containers/ManageTeam";
import TeamDetail from "../containers/ManageTeam/TeamDetails";
import CreateTeam from "../containers/ManageTeam/CreateTeam";
import TeamMember from "../containers/ManageTeam/MemberProfile";
import AddMember from "../containers/ManageTeam/AddMember";

// Calendar
import WeeklyPlan from "../containers/WeeklyPlan";
import AssigneTask from "../containers/WeeklyPlan/AssigneTask";
import WaitingPosts from "../containers/WeeklyPlan/WaitingPosts";

// Gallery
import Gallery from "../containers/Gallery";
import ContentSummary from "../containers/Gallery/ContentSummary";
import CreateContent from "../containers/Gallery/CreateContent";
import PostRequests from "../containers/Gallery/PostRequests";

// Projects
import Projects from "../containers/Projects";
import CreateProject from "../containers/Projects/CreateProject";
import EditContract from "../containers/Projects/EditContract";
import ContractDetail from "../containers/Projects/ContractDetail";

// CMS Pages
import AppVersions from "../containers/CMS/AppVersions";
import ContractLevels from "../containers/CMS/ContractLevels";
import Countries from "../containers/CMS/Countries";
import Faqs from "../containers/CMS/Faqs";
import MailTemplates from "../containers/CMS/MailTemplates";
import SocialMedias from "../containers/CMS/SocialMedias";
import VideoLinks from "../containers/CMS/VideoLinks";

export default function RouterContainer() {
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route path="/" element={<Dashboard />} />

          <Route path="/influencers" element={<Influencers />} />
          <Route path="/influencer/add" element={<InfluencerAdd />} />
          <Route path="/influencer/invite" element={<InviteInfluencer />} />
          <Route path="/influencer/:id" element={<InfluencerDetail />} />

          <Route path="/agent/add" element={<AgentAdd />} />
          <Route path="/agent/:id" element={<AgentDetail />} />

          <Route path="/manage-teams" element={<ManageTeam />} />
          <Route path="/manage-teams/create" element={<CreateTeam />} />
          <Route path="/manage-teams/team-member/:id" element={<TeamMember />} />
          <Route path="/manage-teams/add-member" element={<AddMember />} />
          <Route path="/manage-teams/:id" element={<TeamDetail />} />

          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/create" element={<CreateProject />} />
          <Route path="/projects/contract/:id" element={<ContractDetail />} />                    
          <Route path="/projects/contract/:id/edit" element={<EditContract />} />

          <Route path="/weekly-plan" element={<WeeklyPlan />} />
          <Route path="/weekly-plan/assigne" element={<AssigneTask />} />
          <Route path="/weekly-plan/waiting-posts" element={<WaitingPosts />} />

          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/content-summary" element={<ContentSummary />} />
          <Route path="/gallery/add-content" element={<CreateContent />} />
          <Route path="/gallery/post-requests" element={<PostRequests />} />

          <Route path="/settings" element={<Settings />} />
          <Route path="/app-versions" element={<AppVersions />} />
          <Route path="/contract-levels" element={<ContractLevels />} />
          <Route path="/countries" element={<Countries />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/mail-templates" element={<MailTemplates />} />
          <Route path="/social-medias" element={<SocialMedias />} />
          <Route path="/video-links" element={<VideoLinks />} />
        </Routes>
      </Router>
    </>
  );
}
