export const styles = {
  table: {
  },
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: "12px",
  },
  headCol: {
    flex: 1
  },
  headText: {
    fontSize: "10px",
    color: "#A7A7A7",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  rowCol: {
    
  },
  item: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
};
