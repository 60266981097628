import React, { useEffect, useState } from "react";

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import Input from "../../../components/@core/input";

// Icons
import GalleryIconActive from "../../../assets/icons/nav/icon@gallery-a.svg";
import IconUpload from "../../../assets/icons/icon@upload.svg";
import IconFacebook from "../../../assets/icons/icon@facebook-circle.svg";
import IconTwitter from "../../../assets/icons/icon@twitter-circle.svg";
import IconInstagram from "../../../assets/icons/icon@instagram-circle.svg";
import IconLinkedin from "../../../assets/icons/icon@linkedin-circle.svg";
import IconTrash from "../../../assets/icons/icon@trash-small.svg";

// Styles
import { styles } from "./styles";

function ContentSummary() {
  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={GalleryIconActive} label="Gallery" />
        </Box>
      </Box>

      <Box sx={styles.subLabel}>Add New Content</Box>

      <Box sx={styles.addedItems}>
        <Box sx={styles.item}>
          <img src="https://picsum.photos/115/140?random=1" alt="" />
          <Box sx={styles.removeItem}>
            <img src={IconTrash} alt="" />
          </Box>
        </Box>
        <Box sx={styles.item}>
          <img src="https://picsum.photos/115/140?random=1" alt="" />
          <Box sx={styles.removeItem}>
            <img src={IconTrash} alt="" />
          </Box>
        </Box>
      </Box>

      <Box sx={styles.cols}>
        <Box sx={styles.col}>
          <ContentCard>
            <Box sx={styles.fieldContainer}>
              <Box sx={styles.fieldLabel}>1. Upload the content</Box>
              <Box sx={styles.fileItem}>
                <img src={IconUpload} />
                <Box>
                  <Box sx={styles.fileLabel}>Drag or Upload</Box>
                  <Box sx={styles.fileDesc}>FILE TYPE (MAX: SIZE)</Box>
                </Box>
                <MainButton
                  sx={styles.uploadButton}
                  size="large"
                  variant="contained"
                >
                  Upload
                </MainButton>
              </Box>
            </Box>
          </ContentCard>
          <ContentCard>
            <Box sx={styles.fieldContainer}>
              <Box sx={styles.fieldLabel}>
                2. Wich platforms can share this content?
              </Box>
              <Box sx={styles.fieldDesc}>Select a platform or few.</Box>
              <Box sx={styles.socialMedias}>
                <Box sx={styles.socialMediaItem}>
                  <img src={IconInstagram} alt="" />
                </Box>
                <Box sx={styles.socialMediaItem}>
                  <img src={IconTwitter} alt="" />
                </Box>
                <Box sx={styles.socialMediaItem}>
                  <img src={IconFacebook} alt="" />
                </Box>
                <Box sx={styles.socialMediaItem}>
                  <img src={IconLinkedin} alt="" />
                </Box>
              </Box>
            </Box>
          </ContentCard>
        </Box>
        <Box sx={styles.col}>
          <ContentCard>
            <Box sx={styles.fieldContainer}>
              <Box sx={styles.fieldLabel}>3. Add content details</Box>
              <Input
                sx={styles.multiInput}
                label="Paste an NDA"
                multiline
                rows={11.8}
              />
            </Box>
          </ContentCard>
        </Box>
      </Box>
      <Box sx={styles.continueArea}>
        <MainButton
          size="large"
          variant="contained"
          onClick={() => alert("save")}
        >
          Create Content
        </MainButton>
      </Box>
    </DashboardLayout>
  );
}

export default ContentSummary;
