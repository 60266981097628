import React, { useState, useEffect } from "react";

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import Datatable from "../../../components/@core/datatable";

// Icons
import DashboardIconActive from "../../../assets/icons/nav/icon@dashboard-a.svg";

import {
  textRenderer,
  nameRenderer,
  actionsRenderer,
} from "../../../components/@core/datatable/renderers";

// Services
import { video_links } from "../../../services/video-links";

// Styles
import { styles } from "./styles";

function VideoLinks() {
  const tableHeads = [
    { name: "Name", slug: "name" },
    { name: "Code", slug: "code" },
    { name: "Video", slug: "video" },
    { name: "Type", slug: "type" },
    { name: "#", slug: "action" },
  ];

  const [tableItems, setTableItems] = useState([]);

  useEffect(() => {
    video_links.get_video_links().then((res) => {
      if (res.data) {
        res.data.map((item) => {
          let obj = {
            name: nameRenderer(item.name, item.thumbnailUrl),
            code: textRenderer("??"),
            video: textRenderer(""),
            type: textRenderer(item.type),
            action: actionsRenderer(),
          };
          setTableItems((currentArray) => [...currentArray, obj]);
        });
      }
    });
  }, []);  

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={DashboardIconActive} label="Video Links" />
        </Box>
        <Box>
          <MainButton size="large" variant="contained">
            Create Video Link
          </MainButton>
        </Box>
      </Box>
      <Datatable
        headers={tableHeads}
        items={tableItems}
        tableLabel={"Video Link Items"}
        tableCount={tableItems.length}
      />
    </DashboardLayout>
  );
}

export default VideoLinks;
