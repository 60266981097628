import React, { useEffect, useState } from "react";

// Mui Components
import { Box, Link } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import Input from "../../../components/@core/input";

// Icons
import IconUpload from "../../../assets/icons/icon@upload.svg";
import InfluencersIconActive from "../../../assets/icons/nav/icon@influencers-a.svg";
import IconReferral from "../../../assets/icons/icon@referral-small.svg";

// Styles
import { styles } from "./styles";

const Upload = () => {
  return (
    <Box sx={styles.fileItem}>
      <img src={IconUpload} />
      <Box>
        <Box sx={styles.fileLabel}>Drag or Upload</Box>
        <Box sx={styles.fileDesc}>FILE TYPE (MAX: SIZE)</Box>
      </Box>
      <MainButton sx={styles.uploadButton} size="large" variant="contained">
        Upload
      </MainButton>
    </Box>
  );
};

function AddAgent() {
  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={InfluencersIconActive} label="Influencers" />
        </Box>
      </Box>
      <Box sx={styles.tabs}>
        <Link href="/influencer/add" sx={styles.tabItem}>
          Influencer
        </Link>
        <Link href="/agent/add" sx={[styles.tabItem, styles.tabItemActive]}>
          Agent
        </Link>
      </Box>
      <ContentCard label="Add Agent">
        <Box sx={styles.profileInputs}>
          <Box sx={styles.fieldWrap}>
            <Input label="Name Surname" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input label="E-mail Address" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input label="Password" type="password" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input label="Password" type="password" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input label="Phone Number" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input
              select
              label="Referral"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconReferral} alt="" />
                  </InputAdornment>
                ),
              }}
              defaultValue="select"
            >
              <MenuItem value={"select"}>Select Referral</MenuItem>
              <MenuItem value={"b"}>B</MenuItem>
            </Input>
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input select label="Language" defaultValue="english">
              <MenuItem value={"turkish"}>Turkish</MenuItem>
              <MenuItem value={"english"}>English</MenuItem>
            </Input>
          </Box>
        </Box>
      </ContentCard>
      <Box style={styles.cols}>
        <Box style={styles.col}>
          <ContentCard label="Add Profile Photo">
            <Upload />
          </ContentCard>
        </Box>
        <Box style={styles.col}></Box>
      </Box>
      <MainButton size="large" variant="contained" sx={styles.saveButton}>
        Add Agent
      </MainButton>
    </DashboardLayout>
  );
}

export default AddAgent;
