import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiButton-root": {
      width: "auto!important",
      marginLeft: "15px",
    },
  },
  secondary: {
    paddingLeft: "25px",
    paddingRight: "25px",
    color: "#E02B26",
    backgroundColor: "#FFE4E3",
  },
  influencerSummary: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      //marginRight: "50px",
    },
  },
  nameContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  avatarBadge: {
    position: "absolute",
    right: "-5px",
    top: "2px",
  },
  avatarImg: {
    width: "100%",
    height: "50px",
    objectFit: "cover",
    borderRadius: "100%",
  },
  nameAvatar: {
    position: "relative",
  },
  nameCount: {
    position: "absolute",
    right: "-20px",
    top: "10px",
    display: "inline-block",
    width: "27px",
    height: "27px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    lineHeight: "27px",
    backgroundColor: "#E02B26",
    borderRadius: "100%",
  },
  influencerName: {
    color: "#1F1F1F",
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "35px",
  },
  influencerSubHero: {
    color: "#A7A7A7",
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "35px",
  },
  button: {
    width: "40px",
    height: "40px",
    marginRight: "10px",
    backgroundColor: "#fff",
    border: "solid 1px #E4E4E4",
  },
  influencerValues: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginLeft: "auto",
    "& > *": {
      marginLeft: "50px",
    },
  },
  valueLabel: {
    textAlign: "right",
    color: "#A7A7A7",
    fontSize: "10px",
  },
  valueDetail: {
    textAlign: "right",
    color: "#000",
    fontSize: "22px",
    fontWeight: 600,
  },
  valueDetailRed: {
    color: "#E02B26",
  },
  // summary
  influencerSummaryStats: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "40px",
  },
  influencerSummaryStat: {
    flex: "0 0 19%",
    maxWidth: "19%",
    padding: "15px 20px",
    backgroundColor: "#fff",
    borderRadius: "6px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& img": {
      height: "25px",
      marginRight: "20px",
    },
  },
  statValue: {
    fontSize: "22px",
    fontWeight: 600,
    color: "#1F1F1F",
  },
  statDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
  },
  // social accounts
  socialAccounts: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  socialAccount: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    flex: "0 0 49%",
    maxWidth: "49%",
    padding: "25px",
    backgroundColor: "#fff",
    border: "solid 1px #E4E4E4",
    borderRadius: "6px",
  },
  socialAccountLink: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 600,
    color: "#000",
    textDecoration: "none",
    "& img": {
      marginLeft: "10px",
    },
  },
  socialAccountDesc: {
    fontSize: "14px",
    marginTop: "8px",
  },
  // profile fields
  profileInputs: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrap: {
    flex: "0 0 49%",
    maxWidth: "49%",
    "&:nth-of-type(5) .MuiFormControl-root": {
      marginBottom: 0,
    },
    "&:nth-of-type(6) .MuiFormControl-root": {
      marginBottom: 0,
    },
    "& .MuiFormControl-root .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
    },
  },
  // contracts
  contracts: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contractItem: {
    flex: "0 0 49%",
    maxWidth: "49%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "25px",
    backgroundColor: "#fff",
    border: "solid 1px #E4E4E4",
    borderRadius: "6px",
  },
  contractLabel: {
    color: "#1F1F1F",
    fontSize: "16px",
    fontWeight: 600,
  },
  contractDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginTop: "8px",
  },
  // table
  tableBordered: {
    padding: "30px",
    border: "solid 1px #E4E4E4",
    borderRadius: "25px",
  },
  tableHead: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#A7A7A7",
    fontSize: "10px",
  },
  headCol: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& img": {
      marginLeft: "10px",
    },
  },
  tableRow: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#000",
    fontSize: "14px",
    fontWeight: 500,
    marginTop: "20px",
  },
  rowCol: {
    "& > img": {
      height: "17px",
    },
    "& .MuiButton-root": {
      width: "auto!important",
    },
    "& img": {
      borderRadius: "6px",
    },
  },
  tablePost: {
    position: "relative",
    paddingLeft: "55px",
    lineHeight: "46px",
    "& img": {
      position: "absolute",
      left: 0,
      top: 0,
      borderRadius: "6px",
    },
  },
  tableUser: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  tableUserAvatar: {
    position: "relative",
    marginRight: "10px",
    "& img": {
      borderRadius: "100%!important",
    },
    "& img:nth-of-type(2)": {
      borderRadius: 0,
      position: "absolute",
      right: "-6px",
      top: 0,
    },
  },
  mediaType: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& img": {
      marginRight: "10px",
    },
  },
  actionGroup: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiButton-root": {
      marginRight: "15px",
      alignSelf: "stretch",
    },
    "& .MuiButton-root:first-child": {
      color: "#000",
      backgroundColor: "#fff",
      border: "solid 1px #E4E4E4",
      borderRadius: "6px",
    },
  },
  status: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  statusMark: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    marginRight: "10px",
    backgroundColor: "#0ACE8D",
    borderRadius: "100%",
  },
  // files
  files: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  file: {
    flex: "0 0 49%",
    maxWidth: "49%",
    marginTop: "25px"    
  },
  fileItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#fff",
    border: "dashed 2px #E4E4E4",
    borderRadius: "6px",
    "& .MuiButton-root": {
      width: "auto!important",
    },
    "& > img": {
      marginRight: "20px",
    },
    "& > .MuiButton-root": {
      marginLeft: "auto",
    },
    "& > div:nth-of-type(3)": {
      marginLeft: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    "& > div:nth-of-type(3) .MuiButton-root": {
      alignSelf: "stretch",
      marginLeft: "15px",
    },
  },
  fileLabel: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#000'
  },
  fileDesc: {
    color: '#A7A7A7',
    fontSize: '10px',
    marginTop: '2px'
  },
  fileViewButton: {
    color: '#000',
    border: "solid 1px #E4E4E4",
    backgroundColor: '#F6F6F6',
    '&.MuiButton-root:hover': {
      backgroundColor: '#ccc'
    }
  },
  uploadButton: {
    color: '#000',
    border: "solid 1px #E4E4E4",
    backgroundColor: '#F6F6F6',
    '&.MuiButton-root:hover': {
      backgroundColor: '#ccc'
    }
  },
  saveButton: {
    width: "auto!important",
    marginTop: "20px",
  },  
};
