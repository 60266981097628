import React, { useEffect, useState } from "react";

// Mui Components
import { Box, Link } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import Input from "../../../components/@core/input";

// Icons
import InfluencersIconActive from "../../../assets/icons/nav/icon@influencers-a.svg";
import IconReferral from "../../../assets/icons/icon@referral-small.svg";

// Styles
import { styles } from "./styles";

function InviteInfluencer() {
  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={InfluencersIconActive} label="Influencers" />
        </Box>
      </Box>
      <ContentCard label="Invite Influencer">
        <Box sx={styles.profileInputs}>
          <Box sx={styles.fieldWrap}>
            <Input label="Name Surname" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input label="E-mail Address" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input label="Phone Number" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input
              select
              label="Referral"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconReferral} alt="" />
                  </InputAdornment>
                ),
              }}
              defaultValue="select"
            >
              <MenuItem value={"select"}>Select Referral</MenuItem>
              <MenuItem value={"b"}>B</MenuItem>
            </Input>
          </Box>
        </Box>
      </ContentCard>
      <Box sx={styles.buttons}>
        <MainButton size="large" variant="contained" sx={styles.saveButton}>
          Send Invitation Link
        </MainButton>
        <MainButton size="large" variant="contained" sx={styles.secondary}>
          Copy Invitation Link
        </MainButton>
      </Box>
    </DashboardLayout>
  );
}

export default InviteInfluencer;
