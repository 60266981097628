import React from "react";
import { useNavigate } from "react-router-dom";

// Mui Components
import { Typography, Box, Avatar } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Components
import ContentCard from "../../@core/content-card";
import DropdownButton from "../../@core/dropdown-button";

// Styles
import { styles, StyledMenu } from "./styles";

// Utils
import moment from "moment";

const RecentlySignedUsers = (props) => {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { users } = props;

  return (
    <ContentCard label="Recently Signed Users" transparent count={users.length}>
      <Box sx={styles.table}>
        <Box sx={styles.head}>
          <Box sx={styles.headCol}>
            <Typography sx={styles.headText}>INFLUENCER</Typography>
          </Box>
          <Box sx={styles.headCol}>
            <Typography sx={styles.headText}>DATE</Typography>
          </Box>
        </Box>
        {users.slice(0, 3).map((user, idx) => {
          return (
            <Box sx={styles.row} onClick={() => navigate(`/influencer/${user.id}`)} key={idx}>
              <Box sx={styles.rowCol}>
                <Box sx={styles.item}>
                  <Box sx={styles.left}>
                    <Avatar>{user.firstName.substring(0, 1)}</Avatar>
                  </Box>
                  <Typography sx={styles.label}>
                    {user.firstName} {user.lastName}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.rowCol}>
                <Box sx={styles.date}>
                  <Typography sx={styles.dateLabel}>
                    {moment(user.createdDate).format("MM.DD.YYYY")}
                  </Typography>
                  <Typography sx={styles.hour}>
                    {moment(user.createdDate).format("HH:mm A")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </ContentCard>
  );
};

export default RecentlySignedUsers;
