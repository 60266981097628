// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";

// Styles
import { styles } from "./styles";

// Icons
import DashboardIconActive from "../../../assets/icons/nav/icon@dashboard-a.svg";

function AppVersions() {
  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={DashboardIconActive} label="App Versions" />
        </Box>
        <Box>
          <MainButton size="large" variant="contained">
            Create Version
          </MainButton>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default AppVersions;
