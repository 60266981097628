export const styles = {
  sidebar: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: '50px',
    "& a": {
      color: "#000",
      textDecoration: "none!important",
    },
    "& a[aria-current='page']": {
      "& .MuiButton-root": {
        color: "#fff",
        backgroundColor: "#E02B26",
        boxShadow: "0px 7px 24px rgba(224, 43, 38, 0.30)",
        "& .normal": {
          display: "none",
        },
        "& .active": {
          display: "block",
        },
      },
    },
  },
  message: {
    color: "#A7A7A7",
    fontSize: "14px",
    fontWeight: 500,
    paddingRight: "40px",
    marginBottom: "50px",
  },
  seperatorText: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginBottom: "20px",
  },
  navigation: {
    width: "100%",
    marginBottom: "12px",
    padding: "14px",
    fontSize: "14px",
    textTransform: "unset!important",
    justifyContent: "flex-start",
    transition: "all .3s linear",
    "& .active": {
      display: "none",
    },
    "&:hover": {
      color: "#fff",
      backgroundColor: "#E02B26",
      boxShadow: "0px 7px 24px rgba(224, 43, 38, 0.30)",
      "& .normal": {
        display: "none",
      },
      "& .active": {
        display: "block",
      },
    },
  },
  group: {
    marginTop: "50px",
  },
  images: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingRight: "40px",
  },
  linkWrap: {
    display: "block",
    marginLeft: "45px",
    marginBottom: "10px",
    fontSize: "12px",
    transition: "all .3s linear!important",
    "&:hover": {
      color: "#E02B26!important",
    },
    "& [aria-current='page']": {
      color: "#E02B26!important",
    },
  },
};
