import { request } from "../axios";

export const video_links = {
  get_video_links: (params, lang) => {
    return request.get(`/video-links`, params);
  },
  add_video_link: (params) => {
    return request.post(`/video-links`, params);
  },
  update_video_link: (params, id) => {
    return request.put(`/video-links/${id}`, params);
  },
  get_video_link: (params, id) => {
    return request.get(`/video-links/${id}`, params);
  },  
  remove_video_link: (params, id) => {
    return request.delete(`/video-links/${id}`, params);
  },
};
