// Icons
import RegisteredIcon from "../../../assets/icons/icon@registered.svg";
import UnContractableIcon from "../../../assets/icons/icon@uncontract.svg";
import WaitingIcon from "../../../assets/icons/icon@waiting.svg";
import RejectedIcon from "../../../assets/icons/icon@rejected.svg";
import ReferredIcon from "../../../assets/icons/icon@referred.svg";
import PitchBookedIcon from "../../../assets/icons/icon@booked.svg";
import DidntIcon from "../../../assets/icons/icon@didnt.svg";
import SignedIcon from "../../../assets/icons/icon@signed.svg";
import AwaitingIcon from "../../../assets/icons/icon@awaiting.svg";
import PowerAdminIcon from "../../../assets/icons/icon@power-admin.svg";
import AdminIcon from "../../../assets/icons/icon@admin.svg";
import ModeratorIcon from "../../../assets/icons/icon@moderator.svg";
import MemberIcon from "../../../assets/icons/icon@member.svg";
import InactiveIcon from "../../../assets/icons/icon@inactive.svg";

export const contractedStatuses = [
  {
    title: "Registered",
    icon: RegisteredIcon
  },
  {
    title: "Un-Contactable",
    icon: UnContractableIcon
  },
  {
    title: "Waiting to C.",
    icon: WaitingIcon
  },
  {
    title: "Rejected",
    icon: RejectedIcon
  },
  {
    title: "Referred",
    icon: ReferredIcon
  },
  {
    title: "Pitch Booked",
    icon: PitchBookedIcon
  },
  {
    title: "Didn’t Attend",
    icon: DidntIcon
  },
  {
    title: "C. Signed",
    icon: SignedIcon
  },
  {
    title: "Awaiting Response",
    icon: AwaitingIcon
  }
];

export const roles = [
  {
    title: "Power Admin",
    icon: PowerAdminIcon
  },
  {
    title: "Admin",
    icon: AdminIcon
  },
  {
    title: "Moderator",
    icon: ModeratorIcon
  },
  {
    title: "Member",
    icon: MemberIcon
  },
  {
    title: "Inactive",
    icon: InactiveIcon
  }
];
