import axios from "axios";
import toast from "react-hot-toast";

export const request = axios.create({
  baseURL: "https://crmapi.wpro.social/v1",//process.env.REACT_APP_API_URL,
  timeout: 1000,
});

request.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error

    if (error.response.data.message) {
      let message = null
      if (typeof error.response.data.message == "string") {
        message = [error.response.data.message]

        if(error.response.data.message == "Unauthorized") {
          window.location.href = "/login"
        }

      } else {
        message = error.response.data.message
      }

      toast.error(message.join("\n"), {
        style: {
          border: "1px solid #E02B26",
          fontSize: "14px",
          color: "#E02B26",
        },
      });
    } else {
      if (error.response.data.error) {
        toast.error(error.response.data.error, {
          style: {
            border: "1px solid #E02B26",
            fontSize: "14px",
            color: "#E02B26",
          },
        });
      }
    }

    return Promise.reject(error);
  }
);
