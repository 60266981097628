import React, { useState, useEffect } from "react";

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import Datatable from "../../../components/@core/datatable";

// Services
import { faqs } from "../../../services/faqs";

// Icons
import DashboardIconActive from "../../../assets/icons/nav/icon@dashboard-a.svg";

import {
  textRenderer,
  actionsRenderer,
} from "../../../components/@core/datatable/renderers";

// Styles
import { styles } from "./styles";

function Faqs() {
  const tableHeads = [
    { name: "Title", slug: "title" },
    { name: "Summary", slug: "summary" },
    { name: "Description", slug: "description" },
    { name: "Language Code", slug: "languageCode" },
    { name: "Order Number", slug: "orderNumber" },
    { name: "#", slug: "action" },
  ];

  const tableItems = [
    /*{
      title: textRenderer("FAQ Item"),
      summary: textRenderer("FAQ Summary"),
      description: textRenderer("FAQ Desc"),
      languageCode: textRenderer("EN"),
      orderNumber: textRenderer("1"),
      action: actionsRenderer(),
    }*/
  ];


  useEffect(() => {
    faqs.get_faqs({}, "a58e2f22-2a5b-4d80-bcad-4c6c6a2260b9", "en").then((res) => {
      //console.log(res.data)
    });
  }, []);  

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={DashboardIconActive} label="Faqs" />
        </Box>
        <Box>
          <MainButton size="large" variant="contained">
            Create Faq Item
          </MainButton>
        </Box>
      </Box>
      <Datatable headers={tableHeads} items={tableItems} tableLabel={"Faq Items"} tableCount={"0"} />
    </DashboardLayout>
  );
}

export default Faqs;
