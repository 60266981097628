export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiButton-root": {
      width: "auto!important",
      marginLeft: "15px",
    },
  },
  coverHero: {
    marginTop: "30px",
    marginBottom: "50px",
    position: "relative",
    borderRadius: "50px",
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      background: "linear-gradient(transparent, #000)",
      borderRadius: "50px",
    },
    "& > img": {
      display: "block",
      width: "100%",
      height: "250px",
      objectFit: "cover",
      borderRadius: "50px",
    },
  },
  coverInfo: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    display: "flex",
    flexWwrap: "wrap",
    alignItems: "center",
    paddingLeft: "50px",
    paddingRight: "50px",
    marginBottom: "-50px",
    zIndex: 7,
    color: "#fff",
    "& > img": {
      position: "relative",
      bottom: "-30px",
      display: "inline-block",
      border: "solid 25px #fff",
      borderRadius: "100%",
    },
  },
  col: {
    marginLeft: "40px",
  },
  name: {
    fontSize: "22px",
    fontWeight: 600,
  },
  desc: {
    fontSize: "10px",
  },
  label: {
    fontSize: "22px",
    fontWeight: 600,
  },
  value: {
    fontSize: "10px",
  },
};
