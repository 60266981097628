import React, { useState, useEffect } from "react";

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import Datatable from "../../../components/@core/datatable";

// Icons
import DashboardIconActive from "../../../assets/icons/nav/icon@dashboard-a.svg";

import {
  textRenderer,
  actionsRenderer,
} from "../../../components/@core/datatable/renderers";

// Styles
import { styles } from "./styles";

function ContractLevels() {
  const tableHeads = [
    { name: "Level", slug: "level" },
    { name: "Year", slug: "year" },
    { name: "First Title", slug: "firstTitle" },
    { name: "First Value", slug: "firstValue" },
    { name: "Second Title", slug: "secondTitle" },
    { name: "Second Value", slug: "secondValue" },
    { name: "Third Title", slug: "thirdTitle" },
    { name: "Third Value", slug: "thirdValue" },
    { name: "Currency Type", slug: "currencyType" },
    { name: "Language Code", slug: "languageCode" },
    { name: "#", slug: "action" },
  ];

  const tableItems = [
    {
      level: textRenderer("1"),
      year: textRenderer("2010"),
      firstTitle: textRenderer("First Title"),
      firstValue: textRenderer("First Value"),
      secondTitle: textRenderer("Second Title"),
      secondValue: textRenderer("Second Value"),
      thirdTitle: textRenderer("Third Title"),
      thirdValue: textRenderer("Third Value"),
      currencyType: textRenderer("$"),
      languageCode: textRenderer("EN"),
      action: actionsRenderer(),
    },
    {
      level: textRenderer("1"),
      year: textRenderer("2010"),
      firstTitle: textRenderer("First Title"),
      firstValue: textRenderer("First Value"),
      secondTitle: textRenderer("Second Title"),
      secondValue: textRenderer("Second Value"),
      thirdTitle: textRenderer("Third Title"),
      thirdValue: textRenderer("Third Value"),
      currencyType: textRenderer("$"),
      languageCode: textRenderer("EN"),
      action: actionsRenderer(),
    },
    {
      level: textRenderer("1"),
      year: textRenderer("2010"),
      firstTitle: textRenderer("First Title"),
      firstValue: textRenderer("First Value"),
      secondTitle: textRenderer("Second Title"),
      secondValue: textRenderer("Second Value"),
      thirdTitle: textRenderer("Third Title"),
      thirdValue: textRenderer("Third Value"),
      currencyType: textRenderer("$"),
      languageCode: textRenderer("EN"),
      action: actionsRenderer(),
    },        
  ];

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={DashboardIconActive} label="Contract Levels" />
        </Box>
        <Box>
          <MainButton size="large" variant="contained">
            Create Contract Level
          </MainButton>
        </Box>
      </Box>
      <Datatable
        headers={tableHeads}
        items={tableItems}
        tableLabel={"Contract Level Items"}
        tableCount={"64"}
      />
    </DashboardLayout>
  );
}

export default ContractLevels;
