import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

// Mui Components
import { Box, Avatar } from "@mui/material";

// Layouts
import DashboardLayout from "../../Layouts/Dashboard";

// Components
import MainButton from "../../components/@core/button";
import Hero from "../../components/@core/hero";
import Datatable from "../../components/@core/datatable";

// Icons
import InfluencersIconActive from "../../assets/icons/nav/icon@influencers-a.svg";
import InfluencerIcon from "../../assets/icons/icon@star.svg";
import AgencyIcon from "../../assets/icons/icon@agency.svg";

// Services
import { users, usersFilter } from "../../services/users";

// Styles
import { styles } from "./styles";

// Utils
import moment from "moment";

import { ContractStatuses } from "./formatters";

import {
  influencerNameRenderer,
  postStatusRenderer,
  dateRenderer,
  OpenerRenderer,
} from "../../components/@core/datatable/renderers";

function Influencers() {
  const navigate = useNavigate();
  let location = useLocation();

  const tableHeads = [
    { name: "Name", slug: "name" },
    { name: "Post Status", slug: "post_status" },
    { name: "Account Date", slug: "account_date" },
    { name: "Contracted Status", slug: "contracted_status" },
    { name: "Opener", slug: "opener" },
  ];

  const itemTypes = [
    {
      title: "Influencer",
      icon: InfluencerIcon,
    },
    {
      title: "Agency",
      icon: AgencyIcon,
    },
  ];
  const [usersData, setUsers] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setPage] = useState(1);

  const [filterParams, setFilterParams] = useState(null);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const { keyword, contractStatus } = params;

  const [searchParams, setParams] = useState(null);

  // route change
  useEffect(() => {
    setParams({
      page: 1,
      searchTag: keyword ? keyword : "",
      contractStatus: contractStatus ? contractStatus : "",
    });
  }, [location]);

  const removeFilter = (item) => {
    let params = searchParams;

    setParams(
      Object.keys(params)
        .filter((i) => i !== item)
        .reduce((res, key) => ((res[key] = params[key]), res), {})
    );
  };

  // set page values
  const getPageValues = async () => {
    setUsers([]);

    if (searchParams) {
      const getValues = async () => {
        let { data: openers } = await usersFilter.get_employee_by_type(
          "opener"
        );
        let { data: userData } = await users.get_users(searchParams);

        return {
          openers: openers,
          users: userData,
        };
      };

      toast.promise(getValues(), {
        loading: searchParams.searchTag
          ? `${searchParams.searchTag} searching..`
          : "Influencers loading.",
        success: (res) => {
          if (res.users) {
            let { totalItems, totalPages } = res.users.meta;
            setDataCount(totalItems);
            setMaxPage(totalPages);

            res.users.items.map((user) => {
              let item = {
                name: influencerNameRenderer(
                  `${user.firstName} ${user.lastName}`,
                  null
                ),
                post_status: postStatusRenderer(0, 0, 0),
                account_date: dateRenderer(
                  moment(user.createdDate).format("MM.DD.YYYY"),
                  moment(user.createdDate).format("HH:mm A")
                ),
                opener: OpenerRenderer(user, res.openers, handleOpenerChange),
                contracted_status: `${ContractStatuses[user.contractStatus]}`,
                more: `/influencer/${user.id}`,
              };
              setUsers((currentArray) => [...currentArray, item]);
            });
          }

          return `Influencers loaded.`;
        },
        error: (e) => {
          return `Influencers can't loaded.`;
        },
      });
    }    
  }

  useEffect(() => {
    getPageValues();
  }, [searchParams]);

  const handleTableItemClick = (link) => {
    navigate(link);
  };

  const handleOpenerChange = (user, opener) => {
    if (opener) {
      toast.promise(
        users.update_opener(
          {
            employeeId: opener,
            employeeType: "opener",
          },
          user.id
        ),
        {
          loading: "Opener updating",
          success: (res) => {
            setTimeout(() => {
              getPageValues();
            }, 500)

            return `Opener updated.`;
          },
          error: (e) => {
            return `Opener can't update.`;
          },
        }
      );
    }
  };

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={InfluencersIconActive} label="Influencers" />
        </Box>
        <Box sx={styles.buttons}>
          <Link to="/influencer/invite">
            <MainButton size="large" sx={styles.secondary}>
              Invite
            </MainButton>
          </Link>
          <Link to="/influencer/add">
            <MainButton size="large" variant="contained">
              Add Influencers
            </MainButton>
          </Link>
        </Box>
      </Box>

      <Datatable
        headers={tableHeads}
        items={usersData}
        types={itemTypes}
        tableLabel={"Influencer List"}
        tableCount={dataCount}
        currentPage={currentPage}
        maxPage={maxPage}
        handleChangePage={(e, page) =>
          setParams({ ...searchParams, page: page })
        }
        onClick={handleTableItemClick}
        setFilterParams={(params) => setParams({ ...searchParams, ...params })}
        activeFilters={searchParams}
        removeFilter={(item) => removeFilter(item)}
      />
    </DashboardLayout>
  );
}

export default Influencers;
