// Mui Components
import { Typography, Box } from "@mui/material";

// Components
import ContentCard from "../../@core/content-card";

// Icons
import FacebookIcon from "../../../assets/icons/icon@facebook.svg";
import TwitterIcon from "../../../assets/icons/icon@twitter.svg";
import InstagramIcon from "../../../assets/icons/icon@instagram.svg";

// Styles
import { styles } from "./styles";

const WaitingApproval = () => {
  return (
    <ContentCard label="Waiting approval" count="2">
      <Box sx={styles.table}>
        <Box sx={styles.head}>
          <Box sx={styles.headCol}>
            <Typography sx={styles.headText}>NAME</Typography>
          </Box>
          <Box sx={styles.headCol}>
            <Typography sx={styles.headText}>PLATFORM</Typography>
          </Box>
          <Box sx={styles.headCol}>
            <Typography sx={styles.headText}>DATE</Typography>
          </Box>
          <Box sx={styles.headCol}>
            <Typography sx={styles.headText}>STATUS</Typography>
          </Box>          
          <Box sx={styles.headCol}>
            <Typography sx={styles.headText}>OPENER</Typography>
          </Box>          
          <Box sx={styles.headCol}>
            <Typography sx={styles.headText}>&nbsp;</Typography>
          </Box>          
        </Box>

      </Box>
    </ContentCard>
  );
};

export default WaitingApproval;
