// Mui Components
import { Box, Link } from "@mui/material";

// Components
import ContentCard from "../../../../components/@core/content-card";

// Icons
import IconTargetBlank from "../../../../assets/icons/icon@target-blank.svg";
import IconTwitter from "../../../../assets/icons/icon@twitter-red.svg";
import IconInstagram from "../../../../assets/icons/icon@instagram-red.svg";

// Styles
import { styles } from "../styles";

const SocialAccounts = ({ accounts }) => {
  return (
    <ContentCard label="Social Accounts">
      <Box sx={styles.socialAccounts}>
        <Box sx={styles.socialAccount}>
          <Box>
            <Link href="#" sx={styles.socialAccountLink}>
              @name_srnm
              <img src={IconTargetBlank} alt="" />
            </Link>
            <Box sx={styles.socialAccountDesc}>95.032 Followers</Box>
          </Box>
          <img src={IconTwitter} alt="" />
        </Box>
        <Box sx={styles.socialAccount}>
          <Box>
            <Link href="#" sx={styles.socialAccountLink}>
              @name_srnm
              <img src={IconTargetBlank} alt="" />
            </Link>
            <Box sx={styles.socialAccountDesc}>95.032 Followers</Box>
          </Box>
          <img src={IconInstagram} alt="" />
        </Box>
      </Box>
    </ContentCard>
  );
};

export default SocialAccounts;
