import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Mui Components
import { Box, Checkbox } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import Input from "../../../components/@core/input";
import DropdownButtonIcon from "../../../components/@core/dropdown-button-icon";

// Icons
import ManageTeamIconActive from "../../../assets/icons/nav/icon@manage-team-a.svg";
import IconMember from "../../../assets/icons/icon@member.svg";
import IconUnlist from "../../../assets/icons/icon@unlist.svg";

// Styles
import { styles, StyledMenu } from "./styles";

function AddMember() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={ManageTeamIconActive} label="Manage Teams" />
        </Box>
        <Box sx={styles.buttons}>
          <Link to="/manage-teams/create">
            <MainButton size="large" variant="contained">
              Create New Team
            </MainButton>
          </Link>
        </Box>
      </Box>
      filter will be here
      <ContentCard>
        <Box sx={styles.table}>
          <Box sx={styles.tableHead}>
            <Box sx={[styles.headCol, { flex: "0 0 35%", maxWidth: "35%" }]}>
              USER NAME
            </Box>
            <Box sx={[styles.headCol, { flex: "0 0 20%", maxWidth: "20%" }]}>
              ROLE
            </Box>
            <Box sx={[styles.headCol, { flex: "0 0 15%", maxWidth: "15%" }]}>
              DATE
            </Box>
            <Box sx={[styles.headCol, { flex: "0 0 10%", maxWidth: "10%" }]}>
              LANGUAGE
            </Box>
            <Box sx={[styles.headCol, { flex: "0 0 20%", maxWidth: "20%" }]}>
              STATUS
            </Box>
          </Box>
          <Box sx={styles.tableRow}>
            <Box sx={styles.unlist}>
              <img src={IconUnlist} alt="" />
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 35%", maxWidth: "35%" }]}>
              <Box sx={styles.tableUser}>
                <Checkbox defaultChecked />
                <Box sx={styles.tableUserAvatar}>
                  <img src="https://i.pravatar.cc/34" alt="" />
                </Box>
                <Box>Name Surname</Box>
              </Box>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 20%", maxWidth: "20%" }]}>
              <DropdownButtonIcon
                id="registered-button"
                aria-controls={open ? "registered-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                prevIcon={IconMember}
                sx={styles.dbutton}
                size="large"
              >
                Member
              </DropdownButtonIcon>
              <StyledMenu
                id="registered-menu"
                MenuListProps={{
                  "aria-labelledby": "registered-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} disableRipple>
                  <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                  <FileCopyIcon />
                  Duplicate
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                  <ArchiveIcon />
                  Archive
                </MenuItem>
              </StyledMenu>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 15%", maxWidth: "15%" }]}>
              <Box>
                22.09.2022 <Box sx={styles.hour}>17:54 PM</Box>
              </Box>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 10%", maxWidth: "10%" }]}>
              English
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 20%", maxWidth: "20%" }]}>
              <Box sx={styles.status}>
                <Box sx={styles.statusMark} />
                Online
              </Box>
            </Box>
          </Box>
          <Box sx={styles.tableRow}>
            <Box sx={styles.unlist}>
              <img src={IconUnlist} alt="" />
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 35%", maxWidth: "35%" }]}>
              <Box sx={styles.tableUser}>
                <Checkbox defaultChecked />
                <Box sx={styles.tableUserAvatar}>
                  <img src="https://i.pravatar.cc/34" alt="" />
                </Box>
                <Box>Name Surname</Box>
              </Box>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 20%", maxWidth: "20%" }]}>
              <DropdownButtonIcon
                id="registered-button"
                aria-controls={open ? "registered-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                prevIcon={IconMember}
                sx={styles.dbutton}
                size="large"
              >
                Member
              </DropdownButtonIcon>
              <StyledMenu
                id="registered-menu"
                MenuListProps={{
                  "aria-labelledby": "registered-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} disableRipple>
                  <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                  <FileCopyIcon />
                  Duplicate
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                  <ArchiveIcon />
                  Archive
                </MenuItem>
              </StyledMenu>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 15%", maxWidth: "15%" }]}>
              <Box>
                22.09.2022 <Box sx={styles.hour}>17:54 PM</Box>
              </Box>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 10%", maxWidth: "10%" }]}>
              English
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 20%", maxWidth: "20%" }]}>
              <Box sx={styles.status}>
                <Box sx={styles.statusMark} />
                Online
              </Box>
            </Box>
          </Box>
          <Box sx={styles.tableRow}>
            <Box sx={styles.unlist}>
              <img src={IconUnlist} alt="" />
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 35%", maxWidth: "35%" }]}>
              <Box sx={styles.tableUser}>
                <Checkbox defaultChecked />
                <Box sx={styles.tableUserAvatar}>
                  <img src="https://i.pravatar.cc/34" alt="" />
                </Box>
                <Box>Name Surname</Box>
              </Box>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 20%", maxWidth: "20%" }]}>
              <DropdownButtonIcon
                id="registered-button"
                aria-controls={open ? "registered-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                prevIcon={IconMember}
                sx={styles.dbutton}
                size="large"
              >
                Member
              </DropdownButtonIcon>
              <StyledMenu
                id="registered-menu"
                MenuListProps={{
                  "aria-labelledby": "registered-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} disableRipple>
                  <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                  <FileCopyIcon />
                  Duplicate
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                  <ArchiveIcon />
                  Archive
                </MenuItem>
              </StyledMenu>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 15%", maxWidth: "15%" }]}>
              <Box>
                22.09.2022 <Box sx={styles.hour}>17:54 PM</Box>
              </Box>
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 10%", maxWidth: "10%" }]}>
              English
            </Box>
            <Box sx={[styles.rowCol, { flex: "0 0 20%", maxWidth: "20%" }]}>
              <Box sx={styles.status}>
                <Box sx={styles.statusMark} />
                Online
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentCard>
      <MainButton size="large" variant="contained" sx={styles.saveButton}>
        Add to Team
      </MainButton>
    </DashboardLayout>
  );
}

export default AddMember;
