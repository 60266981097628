export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiButton-root": {
      width: "auto!important",
      marginLeft: "15px",
    },
  },
  secondary: {
    paddingLeft: "25px",
    paddingRight: "25px",
    color: "#E02B26",
    backgroundColor: "#FFE4E3",
  },
  tabs: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "50px",
    "& .MuiTypography-root": {
      marginRight: "70px",
    },
  },
  tabsActive: {
    color: "#A7A7A7",
    pointerEvents: "none",
    cursor: "default",
  },
  listArea: {
    marginTop: "50px",
  },
  listItem: {
    position: "relative",
    cursor: "pointer",
    "& img": {
      display: "block",
      width: "100%",
      borderRadius: "10px",
    },
  },
  badge: {
    position: "absolute",
    left: "10px",
    bottom: "10px",
    padding: "5px",
    display: "inline-block",
    backgroundColor: "#fff",
    borderRadius: "5px",
    "& img": {
      maxHeight: "15px",
      objectFit: "contain",
    },
  },
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBackdrop: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 11,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    position: "relative",
    zIndex: 12,
    width: "900px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "20px",
  },
  modalItem: {
    position: "relative",
    "& > img": {
      width: "100%",
      maxHeight: "600px",
      borderRadius: "20px",
      objectFit: "cover",
    },
  },
  user: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    color: "#fff",
    fontSize: "14px",
    padding: "150px 20px 20px",
    background: "linear-gradient(transparent, #000)",
    borderRadius: "0 0 20px 20px",
  },
  mediaType: {
    padding: "5px",
    display: "inline-block",
    backgroundColor: "#fff",
    borderRadius: "5px",
    marginRight: "10px",
    alignSelf: "flex-start",
    height: "30px",
    "& img": {
      height: "20px",
      objectFit: "contain",
    },
  },
  name: {
    color: "#E02B26",
    fontWeight: 600,
    marginLeft: "10px",
  },
  modalCols: {
    padding: "20px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  modalCol: {
    flex: "0 0 49%",
    maxWidth: "49%",
  },
  modalText: {
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "5px",
    "& strong": {
      fontWeight: 600,
    },
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "20px",
    "& a": {
      color: "#000",
      marginRight: "40px",
    },
  },
  socialMedias: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  socialMediaItem: {
    marginLeft: "10px",
  },
  actionMenu: {
    position: "absolute",
    right: "20px",
    top: "20px",
  },
  actionItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 500,
    paddingTop: "2px",
    paddingBottom: "2px",
    "& > img": {
      marginRight: "10px",
    },
  },
};
