import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E02B26",
    },
    black: {
      main: "#333333",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const strictMode = process.env.NODE_ENV === "production";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  (strictMode && (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  )) || (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  )
);
