import { request } from "../axios";

export const projects = {
  get_projects: (params) => {
    return request.get(`/projects`, params);
  },
  update_project: (params) => {
    return request.put(`/projects/${params.id}`, params);
  },
  get_project: (params) => {
    return request.get(`/projects/${params.id}`, params);
  },
  remove_project: (params) => {
    return request.delete(`/projects/${params.id}`, params);
  },
  deactive_project: (params) => {
    return request.get(`/projects/deactivateProject/${params.id}`, params);
  },
  activate_project: (params) => {
    return request.get(`/projects/activateProject/${params.id}`, params);
  },    
};