// Mui Components
import { Box, Typography } from "@mui/material";

// Styles
import { styles } from "./styles";

const Hero = ({icon, label}) => {
  return (
    <Box sx={styles.hero}>
      <Box sx={styles.icon}>
        <img src={icon} alt={label} />
      </Box>
      <Typography sx={styles.label}>{label}</Typography>
    </Box>
  );
};

export default Hero;
