import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiButton-root": {
      width: "auto!important",
      marginLeft: "15px",
    },
  },
  secondary: {
    paddingLeft: "25px",
    paddingRight: "25px",
    color: "#E02B26",
    backgroundColor: "#FFE4E3",
  },
  influencerSummary: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      //marginRight: "50px",
    },
  },
  nameContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  avatarBadge: {
    position: "absolute",
    right: "-5px",
    top: "2px",
  },
  avatarImg: {
    width: "100%",
    height: "50px",
    objectFit: "cover",
    borderRadius: "100%",
  },
  nameAvatar: {
    position: "relative",
  },
  nameCount: {
    position: "absolute",
    right: "-20px",
    top: "10px",
    display: "inline-block",
    width: "27px",
    height: "27px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    lineHeight: "27px",
    backgroundColor: "#E02B26",
    borderRadius: "100%",
  },
  influencerName: {
    color: "#1F1F1F",
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "35px",
  },
  influencerSubHero: {
    color: "#A7A7A7",
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "35px",
  },
  button: {
    width: "40px",
    height: "40px",
    marginRight: "10px",
    backgroundColor: "#fff",
    border: "solid 1px #E4E4E4",
  },
  influencerValues: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginLeft: "auto",
    "& > *": {
      marginLeft: "50px",
    },
  },  
  teamHeroItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "32px",
    marginBottom: "20px",
    backgroundColor: "#333",
    borderRadius: "24px",
  },
  teamHeroLabel: {
    color: "#fff",
    fontSize: "28px",
    fontWeight: 600,
    marginBottom: "6px",
  },
  teamHeroDesc: {
    color: "#A7A7A7",
    fontSize: "14px",
  },
  teamHeroBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  teamHeroAvatarsWrap: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  teamHeroAvatars: {},
  teamHeroAvatarsDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginLeft: "12px",
  },
  profileInputs: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrap: {
    flex: "0 0 49%",
    maxWidth: "49%",
    "&:nth-of-type(5) .MuiFormControl-root": {
      marginBottom: 0,
    },
    "&:nth-of-type(6) .MuiFormControl-root": {
      marginBottom: 0,
    },
    "& .MuiFormControl-root .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
    },
  },  
  saveButton: {
    width: "auto!important",
    marginTop: "30px",
  },
};
