import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// Mui Components
import { Box, FormControlLabel, Checkbox } from "@mui/material";

// Components
import LoadingButton from "../../components/@core/loading-button";
import Input from "../../components/@core/input";

// Images
import Logo from "../../assets/images/logo@wpro.svg";

// Services
import { auth } from "../../services/auth";

// Store
import { authStore } from "../../store/auth";

// Styles
import { styles } from "./styles";

// Validation
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

function Login() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const isAuth = authStore((state) => state.isAuth);

  const setLogin = authStore((state) => state.updateAuthStatus);
  const setUserTokens = authStore((state) => state.setUserTokens);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      auth
        .post_login({
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          setLogin(true);
          setUserTokens(res.data);
          navigate("/");
          setIsLoading(false);
        })
        .catch((err) => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
    },
  });

  useEffect(() => {
    setLogin(false);
    setUserTokens(null);
  }, [])

  if (isAuth) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.auth}>
        <Box sx={styles.logo}>
          <img src={Logo} alt="" />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Input
              size="small"
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
          <Box>
            <Input
              size="small"
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>
          <FormControlLabel
            sx={{
              marginBottom: "15px",
              "& .MuiTypography-root": { fontSize: "14px" },
            }}
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => setChecked(!isChecked)}
                name="remember"
              />
            }
            label="Remember Me"
          />
          <LoadingButton
            variant="contained"
            size="large"
            type="submit"
            loading={isLoading}
          >
            Login
          </LoadingButton>
        </form>
      </Box>
    </Box>
  );
}

export default Login;
