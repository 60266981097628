export const ContractStatuses = {
  registered: "Registered",
  signed: "Signed",
  notSigned: "Not Signed",
  cancelled: "Cancelled",
  referred: "Referred",
  newLead: "New Lead",
  expired: "Expired",
  special: "Special",
};
