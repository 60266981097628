import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

// Mui Components
import Box from "@mui/material/Box";

// Components
import Header from "../../components/@shared/header";
import Sidebar from "../../components/@shared/sidebar";

// Store
import { authStore } from "../../store/auth";

// Styles
import { styles } from "./styles";

function DashboardLayout({ children }) {
  const isAuth = authStore((state) => state.isAuth);

  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Box>
      <Header />
      <Box sx={styles.wrapper}>
        <Box sx={styles.sidebar}>
          <Sidebar />
        </Box>
        <Box sx={styles.content}>{children}</Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
