import { Formik } from "formik";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";

// Mui Components
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CardActionArea,
} from "@mui/material";

// Icons
import RegisteredIcon from "../../../assets/icons/icon@registered.svg";
import ReferredIcon from "../../../assets/icons/icon@referred.svg";
import RejectedIcon from "../../../assets/icons/icon@rejected.svg";
import WaitingIcon from "../../../assets/icons/icon@waiting.svg";
import AwaitingIcon from "../../../assets/icons/icon@awaiting.svg";
import BookedIcon from "../../../assets/icons/icon@booked.svg";
import DidntIcon from "../../../assets/icons/icon@didnt.svg";
import UncontractIcon from "../../../assets/icons/icon@uncontract.svg";
import SignedIcon from "../../../assets/icons/icon@signed.svg";
import CancelledIcon from "../../../assets/icons/icon@cancelled.svg";

// Components
import MainButton from "../button";

// Styles
import { styles } from "./styles";

// Services
import { usersFilter } from "../../../services/users";

import { FilterFormatter } from "./formatters";

const Filter = ({ setFilterParams, activeFilters, removeFilter }) => {
  const [openers, setOpeners] = useState([]);
  const [owners, setOwners] = useState([]);
  const [closers, setClosers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [contractStatus, setContractStatus] = useState("");
  const [listFilters, setListFilters] = useState([]);

  const categories = [
    {
      icon: RegisteredIcon,
      name: "New Leads",
      link: "/influencers?contractStatus=registered",
      key: "registered",
    },
    {
      icon: SignedIcon,
      name: "Contract Signed",
      link: "/influencers?contractStatus=signed",
      key: "signed",
    },
    {
      icon: AwaitingIcon,
      name: "Contract Not Signed",
      link: "/influencers?contractStatus=notSigned",
      key: "notSigned",
    },
    {
      icon: UncontractIcon,
      name: "Expired Contracts",
      link: "/influencers?contractStatus=expired",
      key: "expired",
    },
    {
      icon: CancelledIcon,
      name: "Cancelled Contracts",
      link: "/influencers?contractStatus=cancelled",
      key: "cancelled",
    },
    {
      icon: ReferredIcon,
      name: "Referred",
      link: "/influencers?contractStatus=referred",
      key: "referred",
    },
    {
      icon: DidntIcon,
      name: "Special Contracts",
      link: "/influencers?contractStatus=special",
      key: "special",
    },
  ];

  useEffect(() => {
    getFilterDetails();
  }, []);

  const getFilterDetails = async () => {
    const filterDetails = async () => {
      let actionUsers = {};
      let { data: employee_types = [] } =
        await usersFilter.get_employee_types();

      for (let type of employee_types) {
        let { data } = await usersFilter.get_employee_by_type(type);
        actionUsers[type] = data;
      }

      let { data: countries } = await usersFilter.get_countries();

      return {
        actionUsers: actionUsers,
        countries: countries,
      };
    };

    toast.promise(filterDetails(), {
      loading: "Filter loading.",
      success: (res) => {
        try {
          setOpeners(res.actionUsers.opener);
          setOwners(res.actionUsers.owner);
          setClosers(res.actionUsers.closer);
          setCountries(res.countries);
        } catch (e) {
          console.log(e);
        }
        return `Filter loaded.`;
      },
      error: () => {
        return `Filter can't loaded.`;
      },
    });
  };

  const findFilterValue = (key, value) => {
    if (key === "searchTag") {
      return value;
    } else {
      if (key === "contractStatus") {
        return categories.filter((i) => i.key === value)[0].name;
      }
      if (key === "openerId") {
        let item = openers.filter((i) => i.id === value)[0];
        return `${item.firstName} ${item.lastName}`;
      }
      if (key === "ownerId") {
        let item = owners.filter((i) => i.id === value)[0];
        return `${item.firstName} ${item.lastName}`;
      }
      if (key === "closerId") {
        let item = closers.filter((i) => i.id === value)[0];
        return `${item.firstName} ${item.lastName}`;
      }
      if (key === "countryCode") {
        let item = countries.filter((i) => i.alpha2Code === value)[0];
        return `${item.name}`;
      }
    }
  };

  useEffect(() => {
    let filters = [];

    if (activeFilters) {
      Object.keys(activeFilters).map((item) => {
        if (item !== "page" && activeFilters[item] !== "") {
          filters.push({
            label: FilterFormatter[item],
            key: item,
            value: findFilterValue(item, activeFilters[item]),
          });
        }
      });
    }

    setListFilters(filters);
  }, [activeFilters]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        searchTag: "",
        openerId: "",
        ownerId: "",
        closerId: "",
        countryCode: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        values["contractStatus"] = contractStatus;
        setFilterParams(values);

        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={styles.filterContainer}>
            <Box sx={[styles.filterItem, styles.filterItemText]}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                name="searchTag"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.searchTag}
              />
            </Box>
            <Box sx={styles.filterItem}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Owner</InputLabel>
                <Select
                  label="Owner"
                  name="ownerId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ownerId}
                >
                  <MenuItem value={""}>Unset</MenuItem>
                  {owners.map((item, key) => {
                    return (
                      <MenuItem value={item.id} key={key}>
                        {item.firstName} {item.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={styles.filterItem}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Opener</InputLabel>
                <Select
                  label="Opener"
                  name="openerId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.openerId}
                >
                  <MenuItem value={""}>Unset</MenuItem>
                  {openers.map((item, key) => {
                    return (
                      <MenuItem value={item.id} key={key}>
                        {item.firstName} {item.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={styles.filterItem}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Closer</InputLabel>
                <Select
                  label="Closer"
                  name="closerId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.closerId}
                >
                  <MenuItem value={""}>Unset</MenuItem>
                  {closers.map((item, key) => {
                    return (
                      <MenuItem value={item.id} key={key}>
                        {item.firstName} {item.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={styles.filterItem}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  label="Country"
                  name="countryCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.countryCode}
                >
                  <MenuItem value={""}>Unset</MenuItem>
                  {countries.map((item, key) => {
                    return (
                      <MenuItem value={item.alpha2Code} key={key}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={styles.filterItem}>
              <MainButton
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                List
              </MainButton>
            </Box>
            <Box sx={styles.filterCategories}>
              {categories.map((item, idx) => {
                return (
                  <CardActionArea
                    key={idx}
                    onClick={() => setContractStatus(contractStatus === item.key ? "" : item.key)}
                    sx={
                      contractStatus === item.key ? styles.activeCategory : null
                    }
                  >
                    <Box sx={styles.icon}>
                      <img src={item.icon} alt={item.name} />
                    </Box>
                    {item.name}
                  </CardActionArea>
                );
              })}
            </Box>

            {listFilters.length > 0 && (
              <Box sx={styles.activeFilters}>
                <Box sx={styles.filtersLabel}>Active Filters</Box>

                {activeFilters &&
                  listFilters.map((item, i) => (
                    <CardActionArea
                      key={i}
                      onClick={() => removeFilter(item.key)}
                      sx={styles.activeFilterItem}
                    >
                      {item.label}: {item.value}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                          fill="rgba(255,255,255,1)"
                        ></path>
                      </svg>
                    </CardActionArea>
                  ))}
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Filter;
