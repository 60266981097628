import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Mui Components
import { Box, Link } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import Input from "../../../components/@core/input";

// Icons
import IconUpload from "../../../assets/icons/icon@upload.svg";
import InfluencersIconActive from "../../../assets/icons/nav/icon@influencers-a.svg";
import IconReferral from "../../../assets/icons/icon@referral-small.svg";
import IconInstagram from "../../../assets/icons/icon@instagram-circle.svg";
import IconTwitter from "../../../assets/icons/icon@twitter-circle.svg";
import IconFacebook from "../../../assets/icons/icon@facebook-circle.svg";
import IconLinkedin from "../../../assets/icons/icon@linkedin-circle.svg";
import IconInstagramRed from "../../../assets/icons/icon@instagram-circle-red.svg";
import IconTwitterRed from "../../../assets/icons/icon@twitter-circle-red.svg";
import IconFacebookRed from "../../../assets/icons/icon@facebook-circle-red.svg";
import IconLinkedinRed from "../../../assets/icons/icon@linkedin-circle-red.svg";

// Form
import { Formik } from "formik";

// Services
import { users } from "../../../services/users";

// Styles
import { styles } from "./styles";

const Upload = () => {
  return (
    <Box sx={styles.fileItem}>
      <img src={IconUpload} />
      <Box>
        <Box sx={styles.fileLabel}>Drag or Upload</Box>
        <Box sx={styles.fileDesc}>FILE TYPE (MAX: SIZE)</Box>
      </Box>
      <MainButton sx={styles.uploadButton} size="large" variant="contained">
        Upload
      </MainButton>
    </Box>
  );
};

function AddInfluencer() {
  const navigate = useNavigate();
  const [socialMediaType, setType] = useState(null);

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={InfluencersIconActive} label="Influencers" />
        </Box>
      </Box>
      <Box sx={styles.tabs}>
        <Link
          href="/influencer/add"
          sx={[styles.tabItem, styles.tabItemActive]}
        >
          Influencer
        </Link>
        <Link href="/agent/add" sx={styles.tabItem}>
          Agent
        </Link>
      </Box>
      <Formik
        enableReinitialize
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.firstName) {
            errors.firstName = "Required";
          }

          if (!values.lastName) {
            errors.lastName = "Required";
          }

          if (!values.mainPhoneNumber) {
            errors.mainPhoneNumber = "Required";
          }

          return errors;
        }}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          mainPhoneNumber: "",
          mainSocialMediaName: "",
          mainSocialMediaType: "",
          followerNumber: 0,
          languageCode: "en",
          isSpecial: false,
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!socialMediaType) {
            toast.error("Please select social media type.", {
              style: {
                border: "1px solid #E02B26",
                fontSize: "14px",
                color: "#E02B26",
              },
            });
          } else {
            values["mainSocialMediaType"] = socialMediaType;
            toast.promise(users.add_user(values), {
              loading: "Influencer saving loading.",
              success: (response) => {
                if (response.data) {
                  setTimeout(() => {
                    navigate("/influencers");
                    setSubmitting(false);
                  }, 3000);
                }

                return `Influencer successfully saved.`;
              },
              error: () => {
                setSubmitting(false);
                //return `Influencer can't saved.`;
              },
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <ContentCard label="Add Influencer">
              <Box sx={styles.profileInputs}>
                <Box sx={styles.fieldWrap}>
                  <Input
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />

                  {errors.firstName && touched.firstName && (
                    <Box sx={styles.fieldError}>{errors.firstName}</Box>
                  )}
                </Box>
                <Box sx={styles.fieldWrap}>
                  <Input
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />

                  {errors.lastName && touched.lastName && (
                    <Box sx={styles.fieldError}>{errors.lastName}</Box>
                  )}
                </Box>
                <Box sx={styles.fieldWrap}>
                  <Input
                    label="E-mail Address"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />

                  {errors.email && touched.email && (
                    <Box sx={styles.fieldError}>{errors.email}</Box>
                  )}
                </Box>
                <Box sx={styles.fieldWrap}>
                  <Input
                    label="Phone Number"
                    name="mainPhoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mainPhoneNumber}
                  />

                  {errors.mainPhoneNumber && touched.mainPhoneNumber && (
                    <Box sx={styles.fieldError}>{errors.mainPhoneNumber}</Box>
                  )}
                </Box>

                <Box sx={styles.fieldWrap}>
                  <Input
                    select
                    label="Language"
                    defaultValue="en"
                    name="languageCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.languageCode}
                  >
                    <MenuItem value={"tr"}>Turkish</MenuItem>
                    <MenuItem value={"en"}>English</MenuItem>
                  </Input>
                </Box>
              </Box>
            </ContentCard>
            <Box style={styles.cols}>
              <Box style={styles.col}>
                <ContentCard label="Add Primary Account">
                  <Box sx={styles.selectPlatformLabel}>
                    Please select a social media platform.
                  </Box>
                  <Box sx={styles.platforms}>
                    <Box
                      sx={styles.platform}
                      onClick={(e) => setType("instagram")}
                    >
                      {socialMediaType == "instagram" ? (
                        <img src={IconInstagramRed} alt="" />
                      ) : (
                        <img src={IconInstagram} alt="" />
                      )}
                    </Box>
                    <Box
                      sx={styles.platform}
                      onClick={(e) => setType("twitter")}
                    >
                      {socialMediaType == "twitter" ? (
                        <img src={IconTwitterRed} alt="" />
                      ) : (
                        <img src={IconTwitter} alt="" />
                      )}
                    </Box>
                    <Box
                      sx={styles.platform}
                      onClick={(e) => setType("facebook")}
                    >
                      {socialMediaType == "facebook" ? (
                        <img src={IconFacebookRed} alt="" />
                      ) : (
                        <img src={IconFacebook} alt="" />
                      )}
                    </Box>
                    <Box
                      sx={styles.platform}
                      onClick={(e) => setType("linkedin")}
                    >
                      {socialMediaType == "linkedin" ? (
                        <img src={IconLinkedinRed} alt="" />
                      ) : (
                        <img src={IconLinkedin} alt="" />
                      )}
                    </Box>
                  </Box>
                  <Box sx={styles.platformNote}>
                    Owner of this social media account should have atleast{" "}
                    <strong>200K followers</strong>
                  </Box>
                  <Input
                    label="@Username"
                    sx={styles.socialMediaInput}
                    name="mainSocialMediaName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mainSocialMediaName}
                  />
                </ContentCard>
              </Box>
              {/*
              <Box style={styles.col}>
                <ContentCard label="Add Profile Photo">
                  <Upload />
                </ContentCard>
                      </Box>*/}
            </Box>
            <MainButton
              size="large"
              variant="contained"
              sx={styles.saveButton}
              type="submit"
              disabled={isSubmitting}
            >
              Add Influencer
            </MainButton>
          </form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default AddInfluencer;
