// Mui Components
import { Box, Link } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import toast from "react-hot-toast";

// Components
import MainButton from "../../../../components/@core/button";
import ContentCard from "../../../../components/@core/content-card";
import Input from "../../../../components/@core/input";

// Icons
import IconReferral from "../../../../assets/icons/icon@referral-small.svg";
import IconLocation from "../../../../assets/icons/icon@location-small.svg";
import IconDown from "../../../../assets/icons/icon@down-small.svg";

// Form
import { Formik } from "formik";

// Services
import { users } from "../../../../services/users";

// Styles
import { styles } from "../styles";

const ProfileDetails = ({ info, reload }) => {
  return (
    <Formik
      enableReinitialize
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }

        if (!values.firstName) {
          errors.firstName = "Required";
        }

        if (!values.lastName) {
          errors.lastName = "Required";
        }

        if (!values.mainPhoneNumber) {
          errors.mainPhoneNumber = "Required";
        }

        return errors;
      }}
      initialValues={{
        firstName: info.firstName ? info.firstName : "",
        lastName: info.lastName ? info.lastName : "",
        email: info.email ? info.email : "",
        mainPhoneNumber: info.mainPhoneNumber ? info.mainPhoneNumber : "",
        followerNumber: info.followerNumber ? info.followerNumber : 0,
        languageCode: info.languageCode ? info.languageCode : "",
        isSpecial: info.isSpecial ? info.isSpecial : false,
        mainSocialMediaName: info.mainSocialMediaName ? info.mainSocialMediaName : ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        toast.promise(users.update_user(values, info.id), {
          loading: "Influencer update loading.",
          success: (response) => {
            if (response.data) {
              setTimeout(() => {
                setSubmitting(false);
                reload();
              }, 1000);
            }

            return `Influencer successfully updated.`;
          },
          error: () => {
            setSubmitting(false);
            return `Influencer can't updated.`;
          },
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <ContentCard label="Profile Details">
            <Box sx={styles.profileInputs}>
              <Box sx={styles.fieldWrap}>
                <Input
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />

                {errors.firstName && touched.firstName && (
                  <Box sx={styles.fieldError}>{errors.firstName}</Box>
                )}
              </Box>
              <Box sx={styles.fieldWrap}>
                <Input
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />

                {errors.lastName && touched.lastName && (
                  <Box sx={styles.fieldError}>{errors.lastName}</Box>
                )}
              </Box>
              <Box sx={styles.fieldWrap}>
                <Input
                  label="E-mail Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />

                {errors.email && touched.email && (
                  <Box sx={styles.fieldError}>{errors.email}</Box>
                )}
              </Box>
              <Box sx={styles.fieldWrap}>
                <Input
                  label="Phone Number"
                  name="mainPhoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mainPhoneNumber}
                />

                {errors.mainPhoneNumber && touched.mainPhoneNumber && (
                  <Box sx={styles.fieldError}>{errors.mainPhoneNumber}</Box>
                )}
              </Box>

              <Box sx={styles.fieldWrap}>
                <Input
                  select
                  label="Language"
                  defaultValue="en"
                  name="languageCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.languageCode}
                >
                  <MenuItem value={"tr"}>Turkish</MenuItem>
                  <MenuItem value={"en"}>English</MenuItem>
                </Input>
              </Box>
            </Box>
          </ContentCard>
          <MainButton
            size="large"
            variant="contained"
            sx={styles.saveButton}
            type="submit"
            disabled={isSubmitting}
          >
            Update Influencer
          </MainButton>
        </form>
      )}
    </Formik>
  );
};

export default ProfileDetails;
