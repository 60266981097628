export const styles = {
  items: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  item: {
    flex: "0 0 49%",
    maxWidth: "49%",
    justifyContent: "flex-start",
    marginBottom: "25px",
    padding: "30px",
    borderRadius: "25px",
    backgroundColor: "#F6F6F6",
    border: "solid 1px #D9D9D9",
  },
  icon: {
    marginRight: "25px",
    "& img": {
      width: '60px'
    }
  },
  text: {},
  name: {
    color: "#000",
    fontSize: "22px",
    fontWeight: 600,
  },
  label: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginTop: "6px",
  },
};
