import { request } from "../axios";

export const social_medias = {
  get_social_medias: (params) => {
    return request.get(`/social-medias`, params);
  },
  add_social_media: (params) => {
    return request.post(`/social-medias`, params);
  },
  update_social_media: (params, id) => {
    return request.put(`/social-medias/${id}`, params);
  },
  get_social_media: (params, id) => {
    return request.get(`/social-medias/${id}`, params);
  },  
  remove_social_media: (params, id) => {
    return request.delete(`/social-medias/${id}`, params);
  },
};