export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiButton-root": {
      width: "auto!important",
      marginLeft: "15px",
    },
  },
  secondary: {
    paddingLeft: "25px",
    paddingRight: "25px",
    color: "#E02B26",
    backgroundColor: "#FFE4E3",
  },
};
