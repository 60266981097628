// Mui Components
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

export const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "40px 0",
    backgroundColor: "#fff",
  },
  logo: {
    flex: "0 0 300px",
    maxWidth: "300px",
    paddingLeft: "50px",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: "0 0 calc(100% - 300px)",
    maxWidth: "calc(100% - 300px)",
    paddingRight: "50px",
  },
  searchWrap: {},
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    width: "40px",
    height: "40px",
    marginLeft: "10px",
    backgroundColor: "#F6F6F6",
  },
  userWrap: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "25px",
  },
  welcome: {
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: "12px",
  },
  title: {
    fontSize: "10px",
    color: "#A7A7A7",
    marginLeft: "12px",
  },
  avatar: {
    width: "50px",
    height: "50px",
  },
  logout: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "20px",
    fontSize: "15px",
    fontWeight: 500,
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
      fill: "#E02B26"
    }
  }
};

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const StyledNotice = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#E02B26",
    color: "#E02B26",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
