import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiButton-root": {
      width: "auto!important",
      marginLeft: "15px",
    },
  },
  secondary: {
    paddingLeft: "25px",
    paddingRight: "25px",
    color: "#E02B26",
    backgroundColor: "#FFE4E3",
  },
  teamHeroItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "32px",
    marginBottom: "20px",
    backgroundColor: "#333",
    borderRadius: "24px",
    marginTop: "50px",
  },
  teamHeroLabel: {
    color: "#fff",
    fontSize: "28px",
    fontWeight: 600,
    marginBottom: "6px",
  },
  teamHeroDesc: {
    color: "#A7A7A7",
    fontSize: "14px",
  },
  teamHeroBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  teamHeroAvatarsWrap: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  teamHeroAvatars: {},
  teamHeroAvatarsDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginLeft: "12px",
  },
  tableHead: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#A7A7A7",
    fontSize: "10px",
  },
  headCol: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& img": {
      marginLeft: "10px",
    },
  },
  tableRow: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#000",
    fontSize: "14px",
    fontWeight: 500,
    marginTop: "20px",
  },
  unlist: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  rowCol: {
    "& > img": {
      height: "17px",
    },
    "& .MuiButton-root": {
      width: "auto!important",
    },
    "& img": {
      borderRadius: "6px",
    },
  },
  tableUser: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  tableUserAvatar: {
    position: "relative",
    marginRight: "10px",
    "& img": {
      borderRadius: "100%!important",
    },
  },
  status: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  statusMark: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    marginRight: "10px",
    backgroundColor: "#0ACE8D",
    borderRadius: "100%",
  },
  hour: {
    color: "#A7A7A7",
    fontSize: "10px",
  },
};
