export const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100vw',
    minHeight: '100vh'
  },
  auth: {
    width: "400px",
    padding: "40px 30px",
    background: "#fff",
    borderRadius: "5px",
    border: 'solid 1px #D9D9D9'
  },
  logo: {
    textAlign: "center",
    marginBottom: "50px",
  },
};
