import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../Layouts/Dashboard";

// Components
import Hero from "../../components/@core/hero";
import MainButton from "../../components/@core/button";

// Icons
import WeeklyIconActive from "../../assets/icons/nav/icon@weekly-a.svg";
import IconChecked from "../../assets/icons/icon@checked.svg";
import IconClose from "../../assets/icons/icon@close.svg";
import IconWarning from "../../assets/icons/icon@warning.svg";
import IconTask from "../../assets/icons/icon@clock.svg";
import IconCall from "../../assets/icons/icon@phone.svg";
import IconMeeting from "../../assets/icons/icon@chat.svg";
import IconPlus from "../../assets/icons/icon@plus.svg";

// Styles
import { styles } from "./styles";

function WeeklyPlan() {
  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={WeeklyIconActive} label="Weekly Plan" />
        </Box>
      </Box>

      <Box sx={styles.summaryContainer}>
        <Box sx={styles.summaryHero}>
          <Box sx={styles.subhead}>This Week’s Summary</Box>
          <MainButton
            size="large"
            variant="contained"
            sx={styles.summaryCreateButton}
          >
            Create Task
            <img src={IconPlus} alt="" />
          </MainButton>
        </Box>

        <Box sx={styles.summaryCards}>
          <Box sx={styles.summaryCard}>
            <Box>
              <img src={IconChecked} alt="" />
            </Box>
            <Box sx={styles.summaryCardDetail}>
              <Box sx={styles.summaryCardValue}>53</Box>
              <Box sx={styles.summaryCardLabel}>SHARED POSTS</Box>
            </Box>
          </Box>
          <Box sx={styles.summaryCard}>
            <Box>
              <img src={IconClose} alt="" />
            </Box>
            <Box sx={styles.summaryCardDetail}>
              <Box sx={styles.summaryCardValue}>32</Box>
              <Box sx={styles.summaryCardLabel}>NOT SHARED POSTS</Box>
            </Box>
          </Box>
          <Box sx={styles.summaryCard}>
            <Box>
              <img src={IconWarning} alt="" />
            </Box>
            <Box sx={styles.summaryCardDetail}>
              <Box sx={styles.summaryCardValue}>127</Box>
              <Box sx={styles.summaryCardLabel}>WAITING POSTS</Box>
            </Box>
          </Box>
          <Box sx={styles.summaryCard}>
            <Box>
              <img src={IconTask} alt="" />
            </Box>
            <Box sx={styles.summaryCardDetail}>
              <Box sx={styles.summaryCardValue}>2</Box>
              <Box sx={styles.summaryCardLabel}>TASK</Box>
            </Box>
          </Box>
          <Box sx={styles.summaryCard}>
            <Box>
              <img src={IconCall} alt="" />
            </Box>
            <Box sx={styles.summaryCardDetail}>
              <Box sx={styles.summaryCardValue}>1</Box>
              <Box sx={styles.summaryCardLabel}>CALL</Box>
            </Box>
          </Box>
          <Box sx={styles.summaryCard}>
            <Box>
              <img src={IconMeeting} alt="" />
            </Box>
            <Box sx={styles.summaryCardDetail}>
              <Box sx={styles.summaryCardValue}>1</Box>
              <Box sx={styles.summaryCardLabel}>MEETING</Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.calendarWrapper}>
        <Calendar isReadOnly={true} />
      </Box>
    </DashboardLayout>
  );
}

export default WeeklyPlan;
