// Mui Components
import { Box, Typography } from "@mui/material";

// Styles
import { styles } from "./styles";

const ContentCard = ({ label, children, transparent, count }) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.label}>
        {label}
        {count && <Box sx={styles.count}>{count}</Box>}
      </Typography>
      <Box sx={[styles.content, transparent ? styles.transparent : null]}>
        {children}
      </Box>
    </Box>
  );
};

export default ContentCard;
