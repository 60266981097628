export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subLabel: {
    fontSize: "16px",
    fontWeight: 600,
    paddingLeft: "30px",
    marginTop: "40px",
    marginBottom: "30px",
  },
  stepContainer: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "30%",
    paddingRight: "30%",
    alignItems: "center",
    justifyContent: "space-between",
    "&::before": {
      position: "absolute",
      content: '""',
      width: "40%",
      height: "5px",
      backgroundColor: "#F6F6F6",
    },
  },
  stepItem: {
    position: "relative",
    zIndex: 6,
    width: "46px",
    height: "46px",
    textAlign: "center",
    lineHeight: "46px",
    color: "#D9D9D9",
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: "#F6F6F6",
    borderRadius: "100%",
  },
  stepItemActive: {
    width: "56px",
    height: "56px",
    color: "#fff",
    fontSize: "22px",
    lineHeight: "56px",
    backgroundColor: "#E02B26",
  },
  fieldLabel: {
    fontSize: "16px",
    fontWeight: 600,
  },
  fieldDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginTop: "10px",
  },
  socialMedias: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: "25px",
  },
  socialMediaItem: {
    cursor: "pointer",
    marginRight: "10px",
  },
  singleInput: {
    marginTop: "20px",
    backgroundColor: "#fff!important",
  },
  multiInput: {
    marginTop: "0px",
    backgroundColor: "#fff!important",
  },
  continueArea: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "25px",
    "& .MuiButton-root": {
      width: "auto!important",
    },
  },
  secondary: {
    color: "#E02B26",
    backgroundColor: "#FFE4E3",
    "&:hover": {
      color: "#E02B26",
      backgroundColor: "#FFE4E3",
    },
  },
  cols: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  col: {
    flex: "0 0 49%",
    maxWidth: "49",
  },
  fileItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "20px",
    marginTop: "20px",
    backgroundColor: "#fff",
    border: "dashed 2px #E4E4E4",
    borderRadius: "6px",
    "& .MuiButton-root": {
      width: "auto!important",
    },
    "& > img": {
      marginRight: "20px",
    },
    "& > .MuiButton-root": {
      marginLeft: "auto",
    },
    "& > div:nth-of-type(3)": {
      marginLeft: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    "& > div:nth-of-type(3) .MuiButton-root": {
      alignSelf: "stretch",
      marginLeft: "15px",
    },
  },
  fileLabel: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#000",
  },
  fileDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginTop: "2px",
  },
  fileViewButton: {
    color: "#000",
    border: "solid 1px #E4E4E4",
    backgroundColor: "#F6F6F6",
    "&.MuiButton-root:hover": {
      backgroundColor: "#ccc",
    },
  },
  uploadButton: {
    color: "#000",
    border: "solid 1px #E4E4E4",
    backgroundColor: "#F6F6F6",
    "&.MuiButton-root:hover": {
      backgroundColor: "#ccc",
    },
  },
  questionLabel: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "20px",
  },
  questionRemove: {
    cursor: "pointer",
    position: "relative",
    bottom: "-2px",
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: "10px",
  },
  addQuestion: {
    width: "auto!important",
  },
};
