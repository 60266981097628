import React, { useEffect, useState } from "react";

// Mui Components
import {
  Box,
  Typography,
  AvatarGroup,
  Avatar,
  IconButton,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import ContentCard from "../../../components/@core/content-card";
import Input from "../../../components/@core/input";
import DropdownButtonIcon from "../../../components/@core/dropdown-button-icon";

// Icons
import ManageTeamIconActive from "../../../assets/icons/nav/icon@manage-team-a.svg";
import IconLocation from "../../../assets/icons/icon@location-small.svg";
import IconReferralInput from "../../../assets/icons/icon@referral-small.svg";
import IconBell from "../../../assets/icons/icon@bell.svg";
import IconMessage from "../../../assets/icons/icon@message.svg";
import IconTrashBlack from "../../../assets/icons/icon@trash-black.svg";
import IconPowerAdmin from "../../../assets/icons/icon@power-admin.svg";

// Styles
import { styles, StyledMenu } from "./styles";

function MemberProfile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={ManageTeamIconActive} label="Manage Teams" />
        </Box>
      </Box>
      <ContentCard label="Profile">
        <Box sx={styles.influencerSummary}>
          <Box>
            <Box sx={styles.nameContainer}>
              <Box sx={styles.nameAvatar}>
                <img
                  src={"https://i.pravatar.cc/51"}
                  alt=""
                  style={styles.avatarImg}
                />
                <Box sx={styles.nameCount}>2</Box>
              </Box>
              <Box>
                <Typography sx={styles.influencerName}>Name Surname</Typography>
                <Typography sx={styles.influencerSubHero}>
                  Power Admin
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginLeft: "20px", marginRight: "20px" }}>
            <DropdownButtonIcon
              id="registered-button"
              aria-controls={open ? "registered-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              prevIcon={IconPowerAdmin}
              sx={styles.dbutton}
              size="large"
            >
              Power Admin
            </DropdownButtonIcon>
            <StyledMenu
              id="registered-menu"
              MenuListProps={{
                "aria-labelledby": "registered-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <EditIcon />
                Edit
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <FileCopyIcon />
                Duplicate
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleClose} disableRipple>
                <ArchiveIcon />
                Archive
              </MenuItem>
            </StyledMenu>
          </Box>
          <Box sx={styles.buttons}>
            <IconButton aria-label="messages" sx={styles.button}>
              <img src={IconBell} alt="" />
            </IconButton>
            <IconButton aria-label="messages" sx={styles.button}>
              <img src={IconMessage} alt="" />
            </IconButton>
            <IconButton aria-label="messages" sx={styles.button}>
              <img src={IconTrashBlack} alt="" />
            </IconButton>
          </Box>
        </Box>
      </ContentCard>
      <ContentCard label="Team" transparent>
        <Box sx={styles.teamHeroItem}>
          <Box>
            <Typography sx={styles.teamHeroLabel}>
              Market Research Team 🤖
            </Typography>
            <Typography sx={styles.teamHeroDesc}>
              We’re researching new components to use on our app
            </Typography>
          </Box>
          <Box sx={styles.teamHeroBottom}>
            <Box sx={styles.teamHeroAvatarsWrap}>
              <AvatarGroup max={6} sx={styles.teamHeroAvatars}>
                <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/34" />
                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/34" />
                <Avatar alt="Cindy Baker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Agnes Walker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Trevor Henderson" src="https://i.pravatar.cc/34" />
              </AvatarGroup>
              <Typography sx={styles.teamHeroAvatarsDesc}>
                15 People Inside
              </Typography>
            </Box>
          </Box>
        </Box>
      </ContentCard>
      <ContentCard label="Profile Details">
        <Box sx={styles.profileInputs}>
          <Box sx={styles.fieldWrap}>
            <Input disabled={true} label="Name Surname" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input disabled label="name.srnm@wpro.com" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input disabled label="000 000 000 00" />
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input
              disabled
              select
              label="Referral"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconReferralInput} alt="" />
                  </InputAdornment>
                ),
              }}
              defaultValue="a"
            >
              <MenuItem value={"a"}>A</MenuItem>
              <MenuItem value={"b"}>B</MenuItem>
            </Input>
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input disabled select label="Language" defaultValue="english">
              <MenuItem value={"turkish"}>Turkish</MenuItem>
              <MenuItem value={"english"}>English</MenuItem>
            </Input>
          </Box>
          <Box sx={styles.fieldWrap}>
            <Input
              disabled
              label="Address Informations"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={IconLocation} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </ContentCard>
      <div>datatable</div>
      <MainButton size="large" variant="contained" sx={styles.saveButton}>
        Update Profile
      </MainButton>
    </DashboardLayout>
  );
}

export default MemberProfile;
