// Mui Components
import LoadingButton from "@mui/lab/LoadingButton";
// Styles
import { styles } from "./styles";

const Button = (props) => {
  return (
    <LoadingButton {...props} sx={styles.button}>
      {props.children}
    </LoadingButton>
  );
};

export default Button;
