import React, { useState } from "react";

// Mui Components
import { Box, Link } from "@mui/material";
import Masonry from "@mui/lab/Masonry";

// Layouts
import DashboardLayout from "../../Layouts/Dashboard";

// Components
import MainButton from "../../components/@core/button";
import Hero from "../../components/@core/hero";
import ContentCard from "../../components/@core/content-card";
import Modal from "./components/modal";

// Icons
import GalleryIconActive from "../../assets/icons/nav/icon@gallery-a.svg";
import IconVideo from "../../assets/icons/icon@video.svg";
import IconPhoto from "../../assets/icons/icon@photo.svg";

// Styles
import { styles } from "./styles";

function Gallery() {
  const [isModalShown, setModalVisibility] = useState(false);

  return (
    <DashboardLayout>
      {isModalShown && <Modal modalHandler={() => setModalVisibility(false)} />}
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={GalleryIconActive} label="Gallery" />
        </Box>
        <Box sx={styles.buttons}>
          <MainButton size="large" sx={styles.secondary}>
            Guidelines
          </MainButton>
          <MainButton size="large" variant="contained">
            Add New Content
          </MainButton>
        </Box>
      </Box>

      <ContentCard label="Available Contents" transparent count="126">
        <Box sx={styles.tabs}>
          <Link href="#" sx={styles.tabsActive}>
            Added By Members
          </Link>
          <Link href="#">Added By Influencers</Link>
          <Link href="#">Waiting Post Requests</Link>
        </Box>

        <Box sx={styles.listArea}>
          <Masonry columns={3} spacing={2}>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/500?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconVideo} alt="" />
              </Box>
            </Box>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/430?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconPhoto} alt="" />
              </Box>
            </Box>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/475?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconPhoto} alt="" />
              </Box>
            </Box>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/215?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconPhoto} alt="" />
              </Box>
            </Box>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/234?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconVideo} alt="" />
              </Box>
            </Box>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/250?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconVideo} alt="" />
              </Box>
            </Box>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/554?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconPhoto} alt="" />
              </Box>
            </Box>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/488?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconPhoto} alt="" />
              </Box>
            </Box>
            <Box sx={styles.listItem} onClick={() => setModalVisibility(true)}>
              <img src="https://picsum.photos/325/450?random=1" alt="" />
              <Box sx={styles.badge}>
                <img src={IconVideo} alt="" />
              </Box>
            </Box>
          </Masonry>
        </Box>
      </ContentCard>
    </DashboardLayout>
  );
}

export default Gallery;
