// Mui Components
import { TextField } from "@mui/material";

// Styles
import { styles } from "./styles";

const Input = (props) => {
  return <TextField {...props} sx={[styles.input, props.sx]} />;
};

export default Input;
