import React, { useState, useEffect } from "react";

// Mui Components
import { Box } from "@mui/material";

// Layouts
import DashboardLayout from "../../../Layouts/Dashboard";

// Components
import MainButton from "../../../components/@core/button";
import Hero from "../../../components/@core/hero";
import Datatable from "../../../components/@core/datatable";

// Icons
import DashboardIconActive from "../../../assets/icons/nav/icon@dashboard-a.svg";

import {
  textRenderer,
  logoRenderer,
  actionsRenderer,
} from "../../../components/@core/datatable/renderers";

// Services
import { social_medias } from "../../../services/social-medias";

// Styles
import { styles } from "./styles";

function SocialMedias() {
  const tableHeads = [
    { name: "Name", slug: "name" },
    { name: "Code", slug: "code" },
    { name: "Logo", slug: "logo" },
    { name: "Min One", slug: "minOne" },
    { name: "Max One", slug: "maxOne" },
    { name: "Min Two", slug: "minTwo" },
    { name: "Max Two", slug: "maxTwo" },
    { name: "Min Three", slug: "minThree" },
    { name: "Minimum Engagement Rate", slug: "engagementRate" },
    { name: "Order Number", slug: "orderNumber" },
    { name: "#", slug: "action" },
  ];

  const [tableItems, setTableItems] = useState([]);

  useEffect(() => {
    social_medias.get_social_medias().then((res) => {
      if (res.data) {
        res.data.map((item) => {
          let obj = {
            name: textRenderer(item.name),
            code: textRenderer("??"),
            logo: logoRenderer(item.logo),
            minOne: textRenderer(item.minFollowerCountForLevelOne),
            maxOne: textRenderer(item.maxFollowerCountForLevelOne),
            minTwo: textRenderer(item.minFollowerCountForLevelTwo),
            maxTwo: textRenderer(item.maxFollowerCountForLevelTwo),
            minThree: textRenderer(item.minFollowerCountForLevelThree),
            engagementRate: textRenderer(item.maxFollowerCountForLevelThree),
            orderNumber: textRenderer(item.orderNumber),
            action: actionsRenderer(),
          };
          setTableItems((currentArray) => [...currentArray, obj]);
        });
      }
    });
  }, []);

  return (
    <DashboardLayout>
      <Box sx={styles.hero}>
        <Box>
          <Hero icon={DashboardIconActive} label="Social Medias" />
        </Box>
        <Box>
          <MainButton size="large" variant="contained">
            Create Social Media Item
          </MainButton>
        </Box>
      </Box>
      <Datatable
        headers={tableHeads}
        items={tableItems}
        tableLabel={"Social Media Items"}
        tableCount={tableItems.length}
      />
    </DashboardLayout>
  );
}

export default SocialMedias;
