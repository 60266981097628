import React from "react";

// Mui Components
import { Box, Link, IconButton, Menu, MenuItem } from "@mui/material";

// Icons
import IconPhoto from "../../../assets/icons/icon@photo.svg";
import IconFacebook from "../../../assets/icons/icon@facebook-circle.svg";
import IconTwitter from "../../../assets/icons/icon@twitter-circle.svg";
import IconInstagram from "../../../assets/icons/icon@instagram-circle.svg";
import IconLinkedin from "../../../assets/icons/icon@linkedin-circle.svg";
import IconTrash from "../../../assets/icons/icon@trash-red.svg";
import IconEdit from "../../../assets/icons/icon@edit-red.svg";
import IconManage from "../../../assets/icons/icon@manage-red.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Styles
import { styles } from "../styles";

const Modal = ({ modalHandler }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={styles.modal}>
      <Box sx={styles.modalBackdrop} onClick={() => modalHandler()} />
      <Box sx={styles.modalContent}>
        <Box sx={styles.modalItem}>
          <IconButton
            aria-label="more"
            id="action-button"
            aria-controls={open ? "action-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={styles.actionMenu}
          >
            <MoreVertIcon sx={{ color: "#fff" }} />
          </IconButton>
          <Menu
            id="action-menu"
            MenuListProps={{
              "aria-labelledby": "action-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Box sx={styles.actionItem}>
                <img src={IconTrash} alt="" />
                Delete
              </Box>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Box sx={styles.actionItem}>
                <img src={IconEdit} alt="" />
                Edit
              </Box>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Box sx={styles.actionItem}>
                <img src={IconManage} alt="" />
                Manage
              </Box>
            </MenuItem>
          </Menu>

          <img src="https://picsum.photos/860/830?random=1" alt="" />

          <Box sx={styles.user}>
            <Box sx={styles.mediaType}>
              <img src={IconPhoto} alt="" />
            </Box>
            Added by Moderator <Box sx={styles.name}>Samuel Flippino</Box>
          </Box>
        </Box>
        <Box sx={styles.modalCols}>
          <Box sx={styles.modalCol}>
            <Box sx={styles.modalText}>
              Elephants are unique creatures and living in mid africa
            </Box>
            <Box sx={styles.modalText}>
              photo by: <strong>@susan.viori</strong>
            </Box>
            <Box sx={styles.tags}>
              <Link href="#">#elephants</Link>
              <Link href="#">#elephants</Link>
              <Link href="#">#elephants</Link>
            </Box>
          </Box>
          <Box sx={styles.modalCol}>
            <Box sx={styles.socialMedias}>
              <Box sx={styles.socialMediaItem}>
                <img src={IconInstagram} alt="" />
              </Box>
              <Box sx={styles.socialMediaItem}>
                <img src={IconTwitter} alt="" />
              </Box>
              <Box sx={styles.socialMediaItem}>
                <img src={IconFacebook} alt="" />
              </Box>
              <Box sx={styles.socialMediaItem}>
                <img src={IconLinkedin} alt="" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Modal;
