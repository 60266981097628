// Mui Components
import { Box, CardActionArea } from "@mui/material";

// Components
import ContentCard from "../../../../components/@core/content-card";

// Icons
import LanistarIcon from "../../../../assets/images/img@lanistar.svg";
import HuhIcon from "../../../../assets/images/img@huh.png";

// Styles
import { styles } from "../styles";

const ActiveContracts = ({ contracts }) => {
  return (
    <ContentCard label="Active Contracts">
      <Box sx={styles.contracts}>
        {contracts.map((item, key) => {
          return (
            <CardActionArea sx={styles.contractItem} key={key}>
              <Box>
                <Box sx={styles.contractLabel}>{item.projectName}</Box>
                <Box sx={styles.contractDesc}>
                  {item.projectEmail} - {item.projectPerson}
                </Box>
              </Box>
              <img src={LanistarIcon} alt="" />
            </CardActionArea>
          );
        })}
      </Box>
    </ContentCard>
  );
};

export default ActiveContracts;
