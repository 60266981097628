// Mui Components
import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  Typography,
} from "@mui/material";

// Components
import MainButton from "../button";

// Icons
import FilterIcon from "../../../assets/icons/icon@filter.svg";

// Styles
import { styles } from "./styles";

import Filter from "./filter";

const Datatable = (props) => {
  const {
    headers,
    items,
    types = [],
    tableLabel,
    tableCount,
    currentPage,
    handleChangePage,
    maxPage,
    onClick,
    setFilterParams,
    activeFilters,
    removeFilter,
  } = props;

  const [isFilterVisible, setFilterVisibility] = useState(false);

  return (
    <>
      <Box sx={styles.tableHeader}>
        <Box sx={styles.labelArea}>
          <Typography sx={styles.label}>{tableLabel}</Typography>
          <Typography sx={styles.count}>{tableCount}</Typography>
        </Box>
        <Box sx={styles.filterMini}>
          <MainButton
            variant="contained"
            aria-label="remove-item"
            sx={styles.filterToggle}
            onClick={() => setFilterVisibility(!isFilterVisible)}
          >
            <img src={FilterIcon} />
          </MainButton>
        </Box>
      </Box>
      {isFilterVisible && (
        <Filter
          setFilterParams={(params) => setFilterParams(params)}
          activeFilters={activeFilters}
          removeFilter={(item) => removeFilter(item)}
        />
      )}
      <Box sx={styles.tableContainer}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {headers.map((header, idx) => {
                  return (
                    <TableCell sx={styles.tableHead} key={idx}>
                      {header.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, rowIdx) => {
                return (
                  <TableRow key={rowIdx}>
                    {headers.map((header, idx) => {
                      const slug = header.slug;
                      return (
                        <TableCell
                          key={idx}
                          sx={styles.tableCell}
                          onClick={() =>
                            slug !== "opener"
                              ? onClick(items[rowIdx].more)
                              : null
                          }
                        >
                          {items[rowIdx][slug]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={styles.tableActions}>
          <Box sx={styles.tableTypes}>
            {types.map((item, idx) => (
              <Box sx={styles.tableType} key={idx}>
                <img src={item.icon} alt="" />
                {item.title}
              </Box>
            ))}
          </Box>
          <Pagination
            count={maxPage}
            defaultPage={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={styles.pagination}
          />
        </Box>
      </Box>
    </>
  );
};

export default Datatable;
