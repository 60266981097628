import { request } from "../axios";

export const users = {
  add_user: (params) => {
    return request.post(`/users`, params);
  },
  get_users: (params) => {
    return request.get(`/users/queryUserById`, { params: params });
  },
  update_user: (params, id) => {
    return request.put(`/users/${id}`, params);
  },
  get_user: (params) => {
    return request.get(`/users/${params.id}`, params);
  },
  remove_user: (params) => {
    return request.delete(`/users/${params.id}`, params);
  },
  search_users: (params) => {
    return request.get(`/users/queryGeneralSearch`, params);
  },
  get_user_by_id: (params) => {
    return request.get(`/users/queryUserById`, params);
  },
  get_social_medias: (params) => {
    return request.get(`/user-socials`, params);
  },
  get_user_contracts: (params) => {
    return request.get(`/user-contracts`, params);
  },
  update_opener: (params, id) => {
    return request.put(`/users/updateAssigned/${id}`, params);
  },
};

export const usersFilter = {
  get_countries: () => {
    return request.get(`/countries`);
  },
  get_employee_types: () => {
    return request.get(`/employee/employee-types`);
  },
  get_employee_by_type: (type) => {
    return request.get(`/employee/employee-types/${type}`);
  },
};
