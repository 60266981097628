export const styles = {
  hero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabs: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: "50px",
  },
  tabItem: {
    display: "inline-block",
    marginRight: "50px",
    color: "#E02B26",
    fontSize: "16px",
    fontWeight: 600,
    textDecoration: "none",
  },
  tabItemActive: {
    pointerEvents: "none",
    color: "#A7A7A7",
    cursor: "default",
  },
  profileInputs: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrap: {
    flex: "0 0 49%",
    maxWidth: "49%",
    "&:nth-of-type(7) .MuiFormControl-root": {
      marginBottom: 0,
    },
    "& .MuiFormControl-root .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
    },
  },
  cols: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  col: {
    flex: "0 0 49%",
    maxWidth: "49%",
    alignSelf: "flex-start",
  },
  fileItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#fff",
    border: "dashed 2px #E4E4E4",
    borderRadius: "6px",
    "& .MuiButton-root": {
      width: "auto!important",
    },
    "& > img": {
      marginRight: "20px",
    },
    "& > .MuiButton-root": {
      marginLeft: "auto",
    },
    "& > div:nth-of-type(3)": {
      marginLeft: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    "& > div:nth-of-type(3) .MuiButton-root": {
      alignSelf: "stretch",
      marginLeft: "15px",
    },
  },
  fileLabel: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#000",
  },
  fileDesc: {
    color: "#A7A7A7",
    fontSize: "10px",
    marginTop: "2px",
  },
  uploadButton: {
    color: "#000",
    border: "solid 1px #E4E4E4",
    backgroundColor: "#F6F6F6",
    "&.MuiButton-root:hover": {
      backgroundColor: "#ccc",
    },
  },
  saveButton: {
    width: "auto!important",
    marginTop: "50px",
  },
};
