// Mui Components
import { Typography, Box, AvatarGroup, Avatar } from "@mui/material";
import { Link } from "react-router-dom";

// Components
import ContentCard from "../../@core/content-card";
import MainButton from "../../@core/button";

// Icons

// Styles
import { styles } from "./styles";

const Teams = () => {
  return (
    <ContentCard label="Teams" transparent>
      <Box sx={styles.items}>
        <Box sx={styles.item}>
          <Typography sx={styles.label}>Market Research Team 🤖</Typography>
          <Typography sx={styles.desc}>
            We’re researching new components to use on our app
          </Typography>
          <Box sx={styles.bottom}>
            <Box sx={styles.avatarsWrap}>
              <AvatarGroup max={6} sx={styles.avatars}>
                <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/34" />
                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/34" />
                <Avatar alt="Cindy Baker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Agnes Walker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Trevor Henderson" src="https://i.pravatar.cc/34" />
              </AvatarGroup>
              <Typography sx={styles.avatarsDesc}>15 People Inside</Typography>
            </Box>
            <Link to="/manage-teams/add-member">
              <MainButton variant="contained" sx={styles.button} size="large">
                Add Member
              </MainButton>
            </Link>
          </Box>
        </Box>
        <Box sx={styles.item}>
          <Typography sx={styles.label}>Schedule Planners 👋</Typography>
          <Typography sx={styles.desc}>
            We’re researching new components to use on our app
          </Typography>
          <Box sx={styles.bottom}>
            <Box sx={styles.avatarsWrap}>
              <AvatarGroup max={4} sx={styles.avatars}>
                <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/34" />
                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/34" />
                <Avatar alt="Cindy Baker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Agnes Walker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Trevor Henderson" src="https://i.pravatar.cc/34" />
              </AvatarGroup>
              <Typography sx={styles.avatarsDesc}>15 People Inside</Typography>
            </Box>
            <Link to="/manage-teams/add-member">
              <MainButton variant="contained" sx={styles.button} size="large">
                Add Member
              </MainButton>
            </Link>
          </Box>
        </Box>
        <Box sx={styles.item}>
          <Typography sx={styles.label}>Social Media Managers</Typography>
          <Typography sx={styles.desc}>
            We’re researching new components to use on our app
          </Typography>
          <Box sx={styles.bottom}>
            <Box sx={styles.avatarsWrap}>
              <AvatarGroup max={6} sx={styles.avatars}>
                <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/34" />
                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/34" />
                <Avatar alt="Cindy Baker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Agnes Walker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Trevor Henderson" src="https://i.pravatar.cc/34" />
              </AvatarGroup>
              <Typography sx={styles.avatarsDesc}>15 People Inside</Typography>
            </Box>
            <Link to="/manage-teams/add-member">
              <MainButton variant="contained" sx={styles.button} size="large">
                Add Member
              </MainButton>
            </Link>
          </Box>
        </Box>
        <Box sx={styles.item}>
          <Typography sx={styles.label}>Influencer Managers 🌟</Typography>
          <Typography sx={styles.desc}>
            We’re researching new components to use on our app
          </Typography>
          <Box sx={styles.bottom}>
            <Box sx={styles.avatarsWrap}>
              <AvatarGroup max={4} sx={styles.avatars}>
                <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/34" />
                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/34" />
                <Avatar alt="Cindy Baker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Agnes Walker" src="https://i.pravatar.cc/34" />
                <Avatar alt="Trevor Henderson" src="https://i.pravatar.cc/34" />
              </AvatarGroup>
              <Typography sx={styles.avatarsDesc}>15 People Inside</Typography>
            </Box>
            <Link to="/manage-teams/add-member">
              <MainButton variant="contained" sx={styles.button} size="large">
                Add Member
              </MainButton>
            </Link>
          </Box>
        </Box>
      </Box>
    </ContentCard>
  );
};

export default Teams;
