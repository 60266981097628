// Mui Components
import { CardActionArea, Typography, Box } from "@mui/material";

// Components
import ContentCard from "../../@core/content-card";

// Icons
import LanistarIcon from "../../../assets/images/img@lanistar.svg";
import HUHIcon from "../../../assets/images/img@huh.png";

// Styles
import { styles } from "./styles";

const values = [
  {
    icon: LanistarIcon,
    name: "Lanistar Ltd Contract",
    label: "Online Challenger Bank",
  },
  {
    icon: HUHIcon,
    name: "HUH Token Contract",
    label: "Crypto Currency Token",
  },
];

const Contracts = props => {
  return (
    <ContentCard label="Available Contracts" transparent>
      <Box sx={styles.items}>
        {values.map((item, idx) => {
          return (
            <CardActionArea sx={styles.item} key={idx}>
              <Box sx={styles.icon}>
                <img src={item.icon} alt={item.name} />
              </Box>
              <Box sx={styles.text}>
                <Typography sx={styles.name}>{item.name}</Typography>
                <Typography sx={styles.label}>{item.label}</Typography>
              </Box>
            </CardActionArea>
          );
        })}
      </Box>
    </ContentCard>
  );
};

export default Contracts;
