export const styles = {
    hero: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
    buttons: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      "& .MuiButton-root": {
        width: "auto!important",
        marginLeft: "15px",
      },
    }
  };
  